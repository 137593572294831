
const base = {
  page: 1,
  sortBy: [],
  sortDesc: [],
  descending: false,
  searchText: '',
  filterStatus: -1,
  pageCount: -1,
}

export default {
  state: {
    pagination: base,
  },

  getters: {
    getPagination: (state) => state.pagination,
  },

  mutations: {
    savePagination(state, data) {
      state.pagination = { ...state.pagination, ...data }
    },

  },

  actions: {
    setPagination({ commit }, payload) {
      commit('savePagination', payload)
    },

    resetPagination({ commit }) {
      // pageCountは自動で変更されるので、設定しないこと
      commit('savePagination', {
        page: 1,
        sortBy: [],
        sortDesc: [],
        descending: false,
        searchText: '',
        filterStatus: -1,
      })
    },
  },

}
