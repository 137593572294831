/*
 * アクセスコード情報ストア
 */

export default {
  state: {
    access_code: {},
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getAccessCode: (state) => {
      return state.access_code
    },
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    saveAccessCode(state, data) {
      state.access_code = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchAccessCode({ commit }, payload) {
      const accessToken = payload.access_token
      await this.$axios.get(`/contract_customers/accesscode/${accessToken}`, {}).then((response) => {
        commit('saveAccessCode', response.data?.body?.data)
      })
    },
  },
}
