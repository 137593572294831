import CONST from '@/data/const'

export default {
  state: {
    isShow: false,
    current: [],
    afterUpdate: null, // update 後のイベント
    showType: CONST.DRAWER_SHOW_TYPE.ON_PAGE // ドロワーの設置場所（デフォルトはページ上）
  },

  getters: {
    isShow: state => state.isShow,
    getCurrent: state => state.current,
    execAfterUpdate: (state) => state.afterUpdate,
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
      state.showType = data.showType || CONST.DRAWER_SHOW_TYPE.ON_PAGE
    },
    saveCurrent(state, data) {
      state.current = data
    },
    saveAfterUpdate(state, data) {
      state.afterUpdate = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },

    setCurrent({ commit }, payload) {
      commit('saveCurrent', payload)
    },

    initCurrent({ commit }) {
      commit('saveCurrent', [])
    },

    setAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', payload)
    },
    clearAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', null)
    },
  },

}
