
const FILTER_STATUS = {
  ALL: 99,
  NOT_SENDING: 0, // 未送信
  WAITING_CONFIRM: 1, // 確認中
  CONFIRMED: 2, // 確認済
  CANCEL: 3, // 取消
  FAILER: 4, // 送信失敗
}

const SEND_STATUS = {
  NOT_SENDING: 0, // 未送信
  FAILER: 10, // 送信失敗
  SUCCESS: 15, // 送信成功
  CANCEL: 20, // 送信取消
}

export default {
  ALL_FILTER_STATUS: FILTER_STATUS.ALL,
  FILTER: [
    { text: 'すべて', value: FILTER_STATUS.ALL },
    { text: '未送信', value: FILTER_STATUS.NOT_SENDING },
    { text: '確認中', value: FILTER_STATUS.WAITING_CONFIRM },
    { text: '確認済', value: FILTER_STATUS.CONFIRMED },
    { text: '取消', value: FILTER_STATUS.CANCEL },
  ],
  FILTER_STATUS,
  SEND_STATUS,

  // step - left menu
  EDIT_STEPS: {
    TITLE: {
      TITLE: 'タイトル',
      STEP: 0,
    },
    TYPE: {
      TITLE: '書類タイプ',
      STEP: 1,
    },
    FILES: {
      TITLE: '配布書類',
      STEP: 2,
    },
    SENDER: {
      TITLE: '送信先',
      STEP: 3,
    },
    CONFIRM: {
      TITLE: '確認',
      STEP: 4,
    },
  },

  // MS-3-6-0-0-0-4 配布書類セット確認状況詳細ダイアログ フィルタ
  CONFIRM_FILTER: [
    { text: '確認済', value: FILTER_STATUS.CONFIRMED },
    { text: '確認中', value: FILTER_STATUS.WAITING_CONFIRM },
    { text: '送信失敗', value: FILTER_STATUS.FAILER },
    { text: '未送信', value: FILTER_STATUS.NOT_SENDING },
  ],
  MAX_SELECT_COUNT: 200,
  MAX_FILE_COUNT: 100,

  CHIP_INFO: {
    NOT_SENDING: {
      color: 'greay lighten-1 gray--text',
      label: '未送信',
      textColor: 'gray',
    },
    CANCEL: {
      color: 'greay lighten-1 gray--text',
      label: '未送信(取消)',
      textColor: 'gray',
    },
    FAILER: {
      color: 'danger lighten-2 white--text',
      label: '送信失敗',
      textColor: 'gray',
    },
    WAITING_CONFIRM: {
      color: 'orange lighten-1 white--text',
      label: '確認中',
      textColor: 'gray',
    },
    CONFIRMED: {
      color: 'primary lighten-1 gray--text',
      label: '確認済',
      textColor: 'gray',
    }
  }
}
