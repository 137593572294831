/*
 * 承認グループ
 */

// import CONST from '@/data/const'

export default {
  state: {
    approvalGroup: [],
  },

  getters: {
    getApprovalGroup: (state) => {
      return state.approvalGroup
    },
  },

  mutations: {
    saveApprovalGroup(state, data) {
      state.approvalGroup = data
    },
  },

  actions: {
    // GET
    async fetchApprovalGroup({ commit }, params) {
      const { article_id } = params

      await this.$axios.get(`/article_approval_group/${article_id}`).then((response) => {
        let approvalGroup = []

        if (response.data?.body?.data) {
          approvalGroup = response.data.body.data
        }

        commit('saveApprovalGroup', approvalGroup)
      })
    },

    // DELETE
    async deleteApprovalGroup({ commit }, params) {
      const { article_id, approval_group_id } = params
      return await this.$axios.delete(`/article_approval_group/${article_id}/${approval_group_id}`)
    },

    // PUT
    async updateApprovalGroup({ commit }, payload) {
      const { article_id, approval_group_id, params } = payload
      return await this.$axios.put(`/article_approval_group/${article_id}/${approval_group_id}`, params)
    },

    // POST
    async postApprovalGroup({ commit }, payload) {
      const { article_id, params } = payload
      return await this.$axios.post(`/article_approval_group/${article_id}`, params)
    },
  },
}
