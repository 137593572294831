/*
 * FIPSテナント情報ストア
 */
export default {
  state: {
    fipsTenants: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getFipsTenants: (state) => {
      return state.fipsTenants
    },
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    saveFipsTenants(state, data) {
      state.fipsTenants = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchFipsTenants({ commit }, article_id) {
      await this.$axios.get('/fips_tenants').then((response) => {
        let fipsTenants
        if (response.data?.body?.data) {
          fipsTenants = response.data.body.data
        } else {
          // 0件
          fipsTenants = []
        }
        commit('saveFipsTenants', fipsTenants)
      })
    },
  },
}
