/*
 * 物件詳細情報ストア
 */

export default {
  state: {
    articleDetail: [],
  },

  getters: {
    getArticleDetail: (state) => {
      return state.articleDetail
    },
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveArticleDetail(state, data) {
      state.articleDetail = data
    },
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // 物件詳細情報一覧取得
    async fetchArticleDetail({ commit }, params) {
      const { articleId } = params
      await this.$axios.get(`/articles/detail/${articleId}`, params).then((response) => {
        let articleDetail = []
        if (response?.data?.body?.data) {
          articleDetail = response.data.body.data
        }
        commit('saveArticleDetail', articleDetail)
      })
    },
    // 物件詳細情報更新
    async updateArticleDetail({ commit }, payload) {
      const { articleId, form } = payload
      const params = {
        items: [
          {
            id: form.id,
            value: form.value || ''
          }
        ]
      }
      return await this.$axios.put(`/articles/detail/${articleId}`, params)
    },
  },
}
