
export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    // 外部Idp情報取得
    async fetchExternalIdp({ commit }, payload) {
      const { tenant_code } = payload
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      return await this.$axios.get(`${apiUrl}/tenants/${tenant_code}/external_idp`)
    },

  },

}
