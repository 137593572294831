export default {
  NOMFA: {
    CODE: 0,
    TYPE: 'NOMFA',
    TEXT: '未設定',
    CHARANGE_NAME: 'MFA_SETUP',
  },
  SMS: {
    CODE: 1,
    TYPE: 'SMS',
    TEXT: 'SMS認証',
    CHARANGE_NAME: 'SMS_MFA',
  },
  TOTP: {
    CODE: 2,
    TYPE: 'TOTP',
    TEXT: '認証アプリ認証',
    CHARANGE_NAME: 'SOFTWARE_TOKEN_MFA',
  },
}
