// import consola from 'consola'
const EMPTY_HOMEBUILDER = {
  id: null,
  homebuilder_number: '',
  name: '',
  mail_address: '',
}

export const DRAWER_TYPE = {
  UPDATE: 'update',
  CREATE: 'create',
}

export default {
  state: {
    title: '',
    type: '',
    isShow: false,
    homebuilder: EMPTY_HOMEBUILDER,
  },

  getters: {
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      state.type = data.type
      state.title = data.title
      state.isShow = data.isShow
      state.homebuilder = data.homebuilder
    },
  },

  actions: {
    showUpdateDrawer({ commit }, payload) {
      const params = {
        type: DRAWER_TYPE.UPDATE,
        title: '宅建士詳細',
        isShow: true,
        // シャローコピーしてからセットする
        homebuilder: Object.assign({}, payload),
      }
      commit('saveDrawer', params)
    },

    showCreateDrawer({ commit }) {
      const params = {
        type: DRAWER_TYPE.CREATE,
        title: '宅建士追加',
        isShow: true,
        // シャローコピーしてからセットする
        homebuilder: Object.assign({}, EMPTY_HOMEBUILDER),
      }

      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        type: '',
        title: '',
        isShow: false,
        homebuilder: {},
      }

      commit('saveDrawer', params)
    },
  },
}
