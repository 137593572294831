
import { mapGetters } from 'vuex'
import { MODE_BASE } from '~/data/navi'
import CONST from '@/data/const'
export default {
  name: 'BaseIndex',

  components: {
    BaseView: () => import('./View'),
    SideBar: () => import('@/layouts/common/Sidebar'),
    AppBar: () => import('@/layouts/common//AppBar'),
    ProgressCircle: () => import('@/components/atoms/ProgressCircle'),
    SnackBar: () => import('@/components/atoms/SnackBar'),
    AuthorizationChip: () => import('@/components/atoms/AuthorizationChip'),
    ConfirmDialog: () => import('@/layouts/common/ConfirmDialog'),
    ChipArticleStatus: () => import('@/components/atoms/ChipArticleStatus'),
  },

  data() {
    return {
      mode: MODE_BASE,
      CONST,
    }
  },

  computed: {
    ...mapGetters('themeConfig', ['getThemeMode']),
    ...mapGetters({
      article: 'article/getArticle',
    }),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
    articleName() {
      return this.article ? this.article.article_name : ''
    },
    isSystemAdmin() {
      return this.$common.isSystemAdmin()
    },
    isArticleAdmin() {
      return this.$common.isArticleAdmin()
    },
  },
  created() {
    this.$store.dispatch('common/setConfirm', this.confirm)
  },
  methods: {
    confirm(params) {
      // params = {title, message}
      return this.$refs.confirmDialog?.open(params)
    },
  },
}
