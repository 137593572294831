import CONST from '@/data/const'

const init = {
  articleWordTemplates: [],
  wordTemplateList: [],
  wordTemplateFile: {},
}

export default {
  state: init,
  getters: {
    getArticleWordTemplates: (state) => {
      return state.articleWordTemplates
    },
    getArticleWordTemplatesByPhase: (state) => {
      return (phase) => {
        return state.articleWordTemplates.filter((wordTemplate) => {
          return wordTemplate.phase === phase
        })
      }
    },
    getWordTemplateList: (state) => {
      return state.wordTemplateList
    },
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    setArticleWordTemplates(state, data) {
      state.articleWordTemplates = data
    },
    setWordTemplateList(state, data) {
      state.wordTemplateList = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchWordTemplatesByAritcleId({ commit }, params) {
      await this.$axios.get('/api/wordTemplates', { articleId: params.articleId }).then(function (response) {
        commit('setArticleWordTemplates', response.data)
      })
    },
    async fetchWordTemplateList({ commit }) {
      await this.$axios.get('/api/wordTemplateList').then(function (response) {
        commit('setWordTemplateList', response.data)
      })
    },

    // wordテンプレートPDF変換
    async getWordTemplateFile({ commit }, params) {
      const word_template_id = params.word_template_id
      const sample_data_flag = params.sample_data_flag || false

      return await this.$axios.post(
        `/word_template_file/${word_template_id}`,
        { sample_data_flag },
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.PDF_FILE }
      ).then((response) => {
        return response.data?.body?.data.pdf_converter_id || null
      })
    },

  },
}
