/*
 * 認証関連
 */

export default {
  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {},

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {},

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    // 2要素認証リセット
    async resetMfa({ commit }, payload) {
      return await this.$axios.put('/authorization/reset_mfa', { id: payload.id })
    },

    // メールアドレス認証情報登録
    async authMail({ commit }, payload) {
      return await this.$axios.post('/authorization', payload)
    },

    // 顧客2要素認証リセット
    async resetMfaCustomer({ commit }, payload) {
      return await this.$axios.put('/authorization/customer/reset_mfa', payload)
    },

    // メールアドレス認証情報登録
    // ローディングマスクを表示せずに、メールアドレス認証情報登録を行う
    async authMailwithoutLoadingOverlay({ commit }, payload) {
      return await this.$axios.post('/authorization', payload, { unShownLoadingOverlay: true, ignoreErrorHandler: true })
    },
  },
}
