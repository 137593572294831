/*
 * 集計情報ストア
 */

export default {
  state: {
    aggregation: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getAggregationList: state => state.aggregation,
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    setAggregationList(state, data) {
      state.aggregation = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchAggregationList({ commit }, params) {
      await this.$axios.get('/aggregation', { params }).then((response) => {
        let aggregationList = []
        if (response?.data?.body?.data) {
          aggregationList = response.data.body.data
        }
        commit('setAggregationList', aggregationList)
      })
    },
  },
}
