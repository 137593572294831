/*
 * 集計（配布書類セット）情報ストア
 */

export default {
  state: {
    aggregation: {},
  },

  getters: {
    getAggregation: state => state.aggregation,
  },

  mutations: {
    saveAggregation(state, data) {
      state.aggregation = data
    },
  },

  actions: {
    // 配布書類セット利用状況取得
    async fetchDistributionDocumentStats({ commit }, params) {
      const res = await this.$axios.get(`/distribution_documents/stats?month=${params.month}`)
      commit('saveAggregation', res.data?.body?.data)
    },
  },
}
