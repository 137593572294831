/*
 * 集計（手付金等保証）情報ストア
 */

export default {
  state: {
    aggregationFipsDepositList: [],
    aggregationWesDepositList: []
  },

  getters: {
    getAggregationFipsDepositList: state => state.aggregationFipsDepositList,
    getAggregationWesDepositList: state => state.aggregationWesDepositList,
  },

  mutations: {
    saveAggregationFipsDepositList(state, data) {
      state.aggregationFipsDepositList = data
    },
    saveAggregationWesDepositList(state, data) {
      state.aggregationWesDepositList = data
    },
  },

  actions: {
    // FIPS手付金等証保証書発行数取得
    async fetchFipsWarrantyCounts({ commit }, params) {
      const res = await this.$axios.get(`/fips_warranty_counts?month=${params.month}`)
      commit('saveAggregationFipsDepositList', res.data?.body?.data || [])
    },
    // WES手付金等証保証書発行数取得
    async fetchWesWarrantyCounts({ commit }, params) {
      const res = await this.$axios.get(`/wes_warranty_counts?month=${params.month}`)
      commit('saveAggregationWesDepositList', res.data?.body?.data || [])
    },
  },
}
