/*
 * 物件詳細情報ドロワーのストア
 */
export default {
  state: {
    isShow: false,
    articleId: null,
    articleDetail: null
  },

  getters: {
    isShow: (state) => {
      return state.isShow
    },
    getArticleId: (state) => {
      return state.articleId
    },
    getArticleDetail: (state) => {
      return state.articleDetail
    }
  },

  mutations: {
    saveShowHide(state, payload) {
      state.isShow = payload
    },
    saveArticleId(state, payload) {
      state.articleId = payload
    },
    saveArticleDetail(state, payload) {
      state.articleDetail = payload
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveShowHide', payload)
    },
    setArticleId({ commit }, payload) {
      const { articleId } = payload
      commit('saveArticleId', articleId)
    },
    closeDrawer({ commit }) {
      commit('saveShowHide', false)
    },
    show({ commit }, articleDetail) {
      commit('saveShowHide', true)
      commit('saveArticleDetail', articleDetail)
    },
  },
}
