// import consola from 'consola'
const EMPTY_USER = {
  id: null,
  user_original_id: '',
  user_name: '',
  department_name: '',
  mail_address: '',
  is_system_admin: false,
  tfa_method_type: 0,
}

export const DRAWER_TYPE = {
  UPDATE: 'update',
  CREATE: 'create',
}

export default {
  state: {
    title: '',
    type: '',
    isShow: false,
    userData: EMPTY_USER,
  },

  getters: {
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      state.type = data.type
      state.title = data.title
      state.isShow = data.isShow
      state.userData = data.userData
    },
  },

  actions: {
    showUpdateDrawer({ commit }, payload) {
      const params = {
        type: DRAWER_TYPE.UPDATE,
        title: 'ユーザー詳細',
        isShow: true,
        // シャローコピーしてからセットする
        userData: Object.assign({}, payload),
      }
      commit('saveDrawer', params)
    },

    showCreateDrawer({ commit }) {
      const params = {
        type: DRAWER_TYPE.CREATE,
        title: 'ユーザー追加',
        isShow: true,
        // シャローコピーしてからセットする
        userData: Object.assign({}, EMPTY_USER),
      }

      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        type: '',
        title: '',
        isShow: false,
        userData: {},
      }

      commit('saveDrawer', params)
    },
  },
}
