
export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    // 監査ログダウンロード
    async download({ commit }, payload) {
      const { article_id, month } = payload
      return await this.$axios.get(`/audit_log/${article_id}?month=${month}`)
    },

  },

}
