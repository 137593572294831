
/*
EX.
import utils from '@/data/utils'

utils.validation.checkFromRules({
  ruleList: CONST.RULES.BASE({ max: 20, required: true }),  // ルール
  value: user_name, // 確認する値
})
*/

// constRulesを使用してバリデーションチェックをする
const checkFromRules = (params) => {
  const { ruleList, value } = params
  let error = false

  for (const rule of ruleList) {
    const result = rule(value)
    if (result !== true) {
      error = result
      break
    }
  }

  // エラーが有る場合: エラーメッセージを返す
  // エラーが無い場合: false
  return error
}

export default {
  checkFromRules,
}
