export default {

  state: {
    warranties: [],
  },

  getters: {
    getWarranties: (state) => {
      return state.warranties
    },
  },

  mutations: {
    saveWarranties(state, data) {
      state.warranties = data
    },
  },

  actions: {
    // [顧客]手付金等保証証書一覧取得
    async fetchWarranties({ commit }) {
      const res = await this.$axios.get('/customer/warranties')
      commit('saveWarranties', res.data?.body?.data || [])
    },

    // [顧客]手付金等保証証書PDFダウンロード
    async downloadFipsWarrantiesPdf({ commit }, params) {
      const fips_warranty_info_id = params.fips_warranty_info_id
      return await this.$axios.get(`/customer/fips_warranties/${fips_warranty_info_id}/pdf`, { responseType: 'blob' }, {
        headers: {
          'content-type': 'application/pdf',
        },
      })
    },

    // [顧客]WES手付金等保証証書PDFダウンロード
    async downloadWesWarrantiesPdf({ commit }, params) {
      const wes_warranty_info_id = params.wes_warranty_info_id
      return await this.$axios.get(`/customer/wes_warranties/${wes_warranty_info_id}/pdf`, { responseType: 'blob' }, {
        headers: {
          'content-type': 'application/pdf',
        },
      })
    },

  },

}
