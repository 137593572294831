import utils from '@/data/utils'
import CONST from '@/data/const'

export const VALIDATION_MODE = {
  ONLY_COUNT: 'only_count',
  CSV_CHECK: 'csv_check',
}

// バリデーションエラーがない場合に戻す値
const NO_ERROR = ''

// 引渡予定日
const _checkDeliveryPlanDate = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.DATE_FORMAT({ required: true, isSlash: true }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:delivery_plan_date,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 契約情報ID
const _checkContractInfoId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.INTEGER({ required: true, max: 20 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:contract_info_id,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// FIPS物件情報ID
const _checkFipsArticleInfoId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: false }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:fips_article_number,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 保証額（手付額）
const _checkWarrantyAmount = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.INTEGER({ required: true, max: 10 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:warranty_amount,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 部屋・区画番号
const _checkLotNumber = (value, row) => {
  const res = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: 20 }),
    value,
  })
  return res
    ? `${res}(キー名:lot_number,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 顧客名
const _checkCustomerName = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: 25 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:customer_name,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 顧客名２
const _checkCustomerName2 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 25 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:customer_name_2,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 顧客名カナ
const _checkCustomerNameKana = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.NAME_KANA({ required: true, max: 100 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:customer_name_kana,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 顧客メールアドレス
const _checkCustomerMailAddress = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.EMAIL(),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:customer_mail_address,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 住所１
const _checkAddress1 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: 25 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:address_1,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 住所２
const _checkAddress2 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: 25 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:address_2,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 住所３
const _checkAddress3 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 25 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:address_3,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 交付日（保証開始日）
const _checkOpenDate = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.DATE_FORMAT({ required: true, isSlash: true }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:open_date,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// 契約ID
const _checkOriginalContractId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.ORIGINAL_CONTRACT_ID({ required: true, max: 64 }),
    value,
  })
  return errorMessage
    ? `${errorMessage}(キー名:original_contract_id,入力値:${value},行番号:${row})`
    : NO_ERROR
}

// FIPS物件番号（fips_article_number）がFIPS物件情報一覧にあるか
const _checkContainFipsArticleNumber = (value, row, list) => {
  const fips_article_number = parseInt(value, 10)
  // 含まない場合 false
  const res = list.includes(fips_article_number)

  return !res
    ? `${CONST.MESSAGE.INPUT_ERR_9003}(キー名:fips_article_number,入力値:${value},行番号:${row})`
    : NO_ERROR
}

/*
 * バリデーションチェック
 * @params {object} item チェックする値
 * @params {int} params.row csv行番号
 * @params {string} params.mode モード（VALIDATION_MODE で指定）
 * @return {array} エラーリスト（エラーなしの場合は、[]）
 */
const validationCheck = (item, params = {}) => {
  const row = params?.row || ''
  const mode = params?.mode || VALIDATION_MODE.ONLY_COUNT
  let checkList = () => { }

  // チェック内容を決定
  switch (mode) {
    case VALIDATION_MODE.CSV_CHECK:
      // CSV 登録用：CSV順に実行
      checkList = (item, row) => [
        _checkContractInfoId(item.contract_info_id, row), // 契約情報ID（ID）
        _checkOriginalContractId(item.original_contract_id, row), // 契約ID
        _checkFipsArticleInfoId(item.fips_article_number, row), // FIPS物件番号
        _checkLotNumber(item.lot_number, row), // 区画番号
        _checkCustomerName(item.customer_name, row), // 名前1（契約者）
        _checkCustomerName2(item.customer_name_2, row), // 名前2（共有者）
        _checkCustomerNameKana(item.customer_name_kana, row), // 名前（カナ）
        _checkAddress1(item.address_1, row), // 住所１
        _checkAddress2(item.address_2, row), // 住所２
        _checkAddress3(item.address_3, row), // 住所３
        _checkWarrantyAmount(item.warranty_amount, row), // 保証額
        _checkOpenDate(item.open_date, row), // 交付日（保証開始日）
        _checkDeliveryPlanDate(item.delivery_plan_date, row), // 引渡予定日

        // FIPS物件番号（fips_article_number）がFIPS物件情報一覧にあるか
        _checkContainFipsArticleNumber(item.fips_article_number, row, params.fipsArticleNumberList),
      ]
      break

    // ボタンの活性・非活性 用
    case VALIDATION_MODE.ONLY_COUNT:
    default:
      checkList = (item, row) => [
        _checkDeliveryPlanDate(utils.date.format(item.delivery_plan_date), row), // 引渡予定日: momentを実施して確認
        _checkContractInfoId(item.fips_warranty_issue_info.contract_info_id, row), // 契約情報ID
        _checkFipsArticleInfoId(item.fips_warranty_issue_info.fips_article_info_id, row), // FIPS物件情報ID
        _checkWarrantyAmount(item.fips_warranty_issue_info.warranty_amount, row), // 保証額（手付額）
        _checkLotNumber(item.fips_warranty_issue_info.lot_number, row), // 部屋・区画番号
        _checkCustomerName(item.fips_warranty_issue_info.customer_name, row), // 顧客名
        _checkCustomerName2(item.fips_warranty_issue_info.customer_name_2, row), // 顧客名２
        _checkCustomerNameKana(item.fips_warranty_issue_info.customer_name_kana, row), // 顧客名カナ
        _checkCustomerMailAddress(item.fips_warranty_issue_info.customer_mail_address, row), // 顧客メールアドレス
        _checkAddress1(item.fips_warranty_issue_info.address_1, row), // 住所１
        _checkAddress2(item.fips_warranty_issue_info.address_2, row), // 住所２
        _checkAddress3(item.fips_warranty_issue_info.address_3, row), // 住所３
        _checkOpenDate(utils.date.format(item.fips_warranty_issue_info.open_date), row), // 交付日（保証開始日）: momentを実施して確認
      ]
      break
  }

  // チェック実行
  const error_list = checkList(item, row)

  // 配列の形で戻す
  return error_list.filter(item => item !== NO_ERROR)
}

export default {
  validationCheck,
}
