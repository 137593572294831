
export default {
  state: {
    isShow: false,
    customer: {},
  },

  getters: {
    getDrawerState: (state) => state,
    getCustomer: (state) => state.customer,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data
    },
    saveCustomer(state, data) {
      state.customer = data
    }
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },
    setCustomer({ commit, }, payload) {
      commit('saveCustomer', { ...payload, })
    },
  },
}
