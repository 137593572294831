import CONST from '@/data/const'

const EMPTY_DISTRIBUTION_DOCUMENT = {
  distribution_document_title: '', // タイトル
  phase_id: CONST.CONTRACT_PHASES[0].value, // 書類タイプ（デフォルト）
  files: [], // 配布書類
  customer_info_ids: [], // 送信先
}

export default {
  state: {
    // dialog
    isShow: false, // 表示・非表示
    step: 0, // タブ
    isCreate: true, // 新規作成かどうか

    // api
    article_id: null,
    distribution_document_id: null,

    // form
    form: EMPTY_DISTRIBUTION_DOCUMENT,
  },

  getters: {
    isShowDialog: (state) => state.isShow,
    getStep: (state) => state.step,
    isCreate: state => state.isCreate,
    getFormData: state => state.form,
    getDistributionDocumentId: state => state.distribution_document_id,
    getArticleId: state => state.article_id,
  },

  mutations: {
    saveShowHide(state, data) {
      state.isShow = data
    },
    saveStep(state, data) {
      state.step = data
    },
    saveIsCreate(state, data) {
      state.isCreate = data
    },
    saveForm(state, data) {
      state.form = data
    },
    saveArticleId(state, data) {
      state.article_id = data
    },
    saveDistributionDocumentId(state, data) {
      state.distribution_document_id = data
    }
  },

  actions: {
    setStep({ commit }, params) {
      commit('saveStep', params)
    },

    // 新規作成
    openCreateDistribtuionDocumentEditDialog({ commit }, params) {
      const { articleId } = params

      commit('saveForm', Object.assign({}, EMPTY_DISTRIBUTION_DOCUMENT))

      commit('saveArticleId', articleId)
      commit('saveDistributionDocumentId', null)

      commit('saveShowHide', true)
      commit('saveStep', 0)
      commit('saveIsCreate', true)
    },

    // 既存編集
    openEditDistributionDocumentEditDialog({ commit }, params) {
      const { distributionDocument, distributionDocumentFiles, distributionDocumentParticipants, step } = params

      commit('saveForm', Object.assign(
        Object.assign({}, EMPTY_DISTRIBUTION_DOCUMENT),
        {
          distribution_document_title: distributionDocument.distribution_document_title, // タイトル
          phase_id: distributionDocument.phase_id, // 書類タイプ
          files: distributionDocumentFiles, // 配布書類
          customer_info_ids: distributionDocumentParticipants.map((participants) => {
            return participants.customer_info_id
          }), // 送信先
        }))
      commit('saveArticleId', distributionDocument.article_id)
      commit('saveDistributionDocumentId', distributionDocument.id)

      commit('saveShowHide', true)
      commit('saveStep', step)
      commit('saveIsCreate', false)
    },

    // ダイアログ初期化
    closeDialog({ commit }) {
      commit('saveForm', EMPTY_DISTRIBUTION_DOCUMENT)

      commit('saveArticleId', null)
      commit('saveDistributionDocumentId', null)

      commit('saveShowHide', false)
      commit('saveStep', 0)
      commit('saveIsCreate', true)
    },

    // 送信先を初期化
    initCustomerInfoIds({ commit, state }) {
      commit('saveForm', Object.assign(state.form, { customer_info_ids: [] }))
    }
  }

}
