/*
 * SET物件情報ストア
 */

export default {
  state: {
    // SET物件一覧
    setArticles: [],
    // SET物件一覧（物件指定）
    setArticlesByArticleId: []
  },

  getters: {
    getSetArticles: (state) => state.setArticles,
    getSetArticlesByArticleId: (state) => state.setArticlesByArticleId,
  },

  mutations: {
    saveSetArticles(state, data) {
      state.setArticles = data
    },
    saveSetArticlesByArticleId(state, data) {
      state.setArticlesByArticleId = data
    },
  },

  actions: {
    // SET物件情報一覧取得
    async fetchSetArticles({ commit },) {
      const res = await this.$axios.get('/set_articles')
      commit('saveSetArticles', res.data?.body?.data || [])
    },
    // SET物件情報一覧取得（物件指定）
    async fetchSetArticlesByArticleId({ commit }, params) {
      const { article_id } = params
      const res = await this.$axios.get(`/articles/${article_id}/set_articles`)
      commit('saveSetArticlesByArticleId', res.data?.body?.data || [])
      return res
    },
    // 物件に紐づくSET物件紐付け情報削除
    async deleteSetArticle({ commit }, payload) {
      return await this.$axios.delete(
        `/articles/${payload.article_id}/set_article_links/${payload.m_article_set_article_id}`,
      )
    },
    // SET物件情報紐付け情報登録
    async addSetArticlesByArticleId({ commit }, payload) {
      return await this.$axios.post(`/articles/${payload.articleId}/set_article_links`, {
        set_article_ids: payload.set_article_ids
      })
    },
  },
}
