
export default {
  state: {
    isShow: false,
    list: null,
  },

  getters: {
    getDrawerState: (state) => state
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
      state.list = data.list
    }
  },

  actions: {
    showDrawer({ commit }, params) {
      commit('saveDrawer', {
        isShow: true,
        list: params.list
      })
    },

    closeDrawer({ commit }) {
      commit('saveDrawer', {
        isShow: false,
        list: null
      })
    },
  },
}
