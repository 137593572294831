// 顧客一覧画面にて、削除済契約者の表示、非表示の状態を維持するstore

export default {
  state: {
    isShowDeletedCustomer: false
  },

  getters: {
    isShowDeletedCustomer: (state) => state.isShowDeletedCustomer,
  },

  mutations: {
    setShowDeletedCustomer(state, value) {
      state.isShowDeletedCustomer = value
    },
  },

  actions: {
    showDeletedCustomer({ commit }, value) {
      commit('setShowDeletedCustomer', value)
    },
  },
}
