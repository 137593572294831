
const EMPTY = {
  id: null,
  original_user_id: '',
  user_name: '',
  mail_address: '',
  department_name: '',
  is_system_admin: null,
  authorization: {
    id: null,
    authorization_code: null,
    authorization_name: '',
  }
}

export default {
  state: {
    title: '',
    type: '',
    isShow: false,
    current: EMPTY,
  },

  getters: {
    getDrawerState: (state) => state,
    getCurrnetUser(state) {
      return state.current
    }
  },

  mutations: {
    saveDrawer(state, data) {
      state.type = data.type
      state.title = data.title
      state.isShow = data.isShow
    },
    saveCurrentUser(state, data) {
      state.current = data
    },
  },

  actions: {
    showUpdateDrawer({ commit }, params) {
      params = Object.assign(
        {
          type: 'update',
          title: 'ユーザー詳細',
          isShow: true,
        },
        params,
      )
      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        type: '',
        title: '',
        isShow: false,
      }

      commit('saveDrawer', params)
    },

    setCurrentUser({ commit }, params = EMPTY) {
      const newData = Object.assign(EMPTY, params)
      commit('saveCurrentUser', newData)
    },

  },
}
