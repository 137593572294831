/*
 * 物件宅建士情報ストア
 */
// import CONST from '@/data/const'
export default {
  state: {
    homebuilders: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getHomebuilders: (state) => {
      return state.homebuilders
    },
    getHomebuilder: (state) => (id) => {
      return state.homebuilders.find((homebuilder) => {
        return id === homebuilder.id
      })
    },
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    saveHomebuilders(state, data) {
      state.homebuilders = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchArticleHomebuilders({ commit }, article_id) {
      await this.$axios.get(`/article_homebuilder/${article_id}`).then((response) => {
        let homebuilders
        if (response.data?.body?.data) {
          homebuilders = response.data.body.data.map((data) => {
            return data.homebuilder
          })
        } else {
          // 0件
          homebuilders = []
        }
        commit('saveHomebuilders', homebuilders)
      })
    },

    async addArticleHomebuilder({ commit }, params) {
      const { article_id, homebuilders } = params
      return await this.$axios.post(`/article_homebuilder/${article_id}`, { homebuilders })
    },

    async deleteArticleHomebuilder({ commit }, params) {
      const { article_id, homebuilder_id } = params
      return await this.$axios.delete(`/article_homebuilder/${article_id}/${homebuilder_id}`)
    },
  },
}
