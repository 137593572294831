import CONST from '../data/const'

const EMPTY_DOCUMENT = {
  id: null,
  contract_document_status: {
    document_status: 0,
  },
  contract_phase: {
    phase_code: 1,
  },
  create_user: {
    user_name: '',
    date: '',
  },
  update_user: {
    user_name: '',
    date: '',
  },
  delegation_flag: 0, // 委任状かどうか
  sales_agreement_flag: 1, // 売買契約書かどうか
  is_created_signed: 0,
  homebuilder: null,
  delegation_customer: {
    company_name: '',
    company_title: '',
    customer_mail_address: '',
    customer_name: '',
    id: '',
    is_company: '',
  },

  approvable_group: {
    id: null,
  },
  request_send_mail: true,

  sales_agreement_history: {
    id: null,
  },
  partner_list: [],
}

export default {
  state: {
    // 以下入力値
    isShow: false,
    step: 0,
    // 物件ID
    articleId: null,
    // 契約ID
    contractId: null,
    // 新規作成かどうか
    isNewDocument: false,
    // 委任状の作成モードかどうか
    delegaTiontargetId: null,

    sourceDocument: {},
    sourceDocumentFiles: [],
    sourceDocumentTemplates: [],
    sourceDocumentParticipants: [],
  },

  getters: {
    isShowState: (state) => state.isShow,
    getStep: (state) => state.step,
    isNewDocument: (state) => state.isNewDocument,
    getArticleId: (state) => state.articleId,
    getContractId: (state) => state.contractId,
    getDelegaTiontargetId: (state) => state.delegaTiontargetId,
    getSourceDocument: (state) => state.sourceDocument,
    getSourceDocumentFiles: (state) => state.sourceDocumentFiles,
    getSourceDocumentTemplates: (state) => state.sourceDocumentTemplates,
    getSourceDocumentParticipants: (state) => state.sourceDocumentParticipants,
  },

  mutations: {
    saveShowState(state, data) {
      state.isShow = data
    },
    saveStep(state, data) {
      state.step = data
    },
    saveIsNewDocument(state, data) {
      state.isNewDocument = data
    },
    saveArticleId(state, data) {
      state.articleId = data
    },
    saveContractId(state, data) {
      state.contractId = data
    },
    saveDelegaTiontargetId(state, data) {
      state.delegaTiontargetId = data
    },
    saveSourceDocument(state, data) {
      state.sourceDocument = data
    },
    saveSourceDocumentFiles(state, data) {
      state.sourceDocumentFiles = data
    },
    saveSourceDocumentTemplates(state, data) {
      state.sourceDocumentTemplates = data
    },
    saveSourceDocumentParticipants(state, data) {
      state.sourceDocumentParticipants = data
    },
  },

  actions: {
    show({ commit }, step) {
      commit('saveShowState', true)
      commit('saveStep', step)
    },
    hide({ commit }) {
      commit('saveShowState', false)
      commit('saveStep', 0)
    },
    setStep({ commit }, payload) {
      commit('saveStep', payload)
    },

    // 契約書類セット作成
    setNewDocument({ commit }, payload) {
      const { articleId, contractId, customers } = payload
      commit('saveIsNewDocument', true)
      commit('saveArticleId', articleId)
      commit('saveContractId', contractId)
      commit('saveDelegaTiontargetId', null)
      commit('saveSourceDocument', { ...EMPTY_DOCUMENT })
      commit('saveSourceDocumentFiles', [])
      commit('saveSourceDocumentTemplates', [])

      // 契約中以外は対象外
      const activeCustomer = customers.filter(customer => customer.customer_status === CONST.CUSTOMER_STATUSES.NORMAL.status)

      commit('saveSourceDocumentParticipants', activeCustomer.map((customer) => {
        // documentParticipantsのID形式に合わせる
        return { customer_info_id: customer.id }
      }))
    },

    // 契約書類セット作成（委任状）
    setNewDelegation({ commit }, payload) {
      const { delegationTargetId, articleId, contractId } = payload
      commit('saveIsNewDocument', true)
      commit('saveArticleId', articleId)
      commit('saveContractId', contractId)
      commit('saveDelegaTiontargetId', delegationTargetId)

      commit('saveSourceDocument', {
        ...EMPTY_DOCUMENT,
        ...{
          delegation_flag: 1,
          sales_agreement_flag: 0
        },
      })
      commit('saveSourceDocumentFiles', [])
      commit('saveSourceDocumentTemplates', [])
      commit('saveSourceDocumentParticipants', [
        { customer_info_id: delegationTargetId }
      ])
    },

    // 契約書類セットの変更
    setEditDocument({ commit }, payload) {
      //
      // payload: {
      //    articleId: 物件ID,
      //    contractId: 契約グループID,
      //    document: 契約書類セット情報,
      //    documentFiles: 契約書類一覧,
      //    documentParticipants: 契約書類セット関係者一覧
      //    phaseTemplate: 契約書類セットの選択フェーズに紐づく契約テンプレート一覧
      // }

      // 委任状かどうか
      let delegaTiontarget = null
      if (payload.document.delegation_flag === 1) {
        delegaTiontarget = payload.document.delegation_customer?.id ? payload.document.delegation_customer.id : null
      }

      // 該当フェーズの選択可能なテンプレート一覧
      const phaseTemplateList = payload.phaseTemplate.template_list
      // アップロード済みファイル一覧
      const pdfFileList = []
      // 選択中テンプレート一覧
      const selectedTemplateList = []
      for (const file of payload.documentFiles) {
        // idをcontract_file_idに置き換える
        const item = {
          ...{ contract_file_id: file.id },
          ...file
        }

        // テンプレートファイルの場合
        if (item.m_word_template_id) {
          // 一時保存した時から名前やフェーズが変わっている場合があるので、最新のテンプレート情報と差し替える
          const currentTemplate = phaseTemplateList.find((template) => {
            return template.id === item.m_word_template_id
          })
          // テンプレートのフェーズが変わっている場合や削除されている場合はスキップする
          if (currentTemplate === undefined || currentTemplate.contract_phase.phase_code === item.phaseId) {
            continue
          }
          selectedTemplateList.push({
            ...item,
            ...currentTemplate,
            ...{
              id: item.m_word_template_id,
            }
          })
        } else {
          pdfFileList.push(item)
        }
      }

      commit('saveIsNewDocument', false)
      commit('saveArticleId', payload.articleId)
      commit('saveContractId', payload.contractId)
      commit('saveDelegaTiontargetId', delegaTiontarget)

      commit('saveSourceDocument', { ...payload.document })
      commit('saveSourceDocumentFiles', pdfFileList)
      commit('saveSourceDocumentTemplates', selectedTemplateList)
      const activeCustomer = payload.documentParticipants.filter(item => item.customer.customer_status !== CONST.CUSTOMER_STATUSES.CANCEL.status)
      commit('saveSourceDocumentParticipants', activeCustomer)
    },
  },
}
