import CONST from '@/data/const'

export default {
  state: {
    selected: [],
  },

  getters: {
    getSelected: (state) => state.selected,
  },

  mutations: {
    saveSelected(state, data) {
      state.selected = data
    },
  },

  actions: {
    async fetchLocalFiles({ commit }, params) {
      const queryList = []
      Object.keys(params).forEach(key => { queryList.push(`${key}=${params[key]}`) })
      const query = queryList.join('&')

      return await this.$axios.get(
        `/local_files?${query}`,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.PDF_FILE }
      )
    }
  }
}
