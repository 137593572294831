// import { logger } from '@/plugins/logger'

// isPageLoading: 画面更新中かどうか（エラー画面にて、画面遷移中にmountedされたのかどうなのかの判定に使用する）
// <更新タイミング>
// ・画面遷移時(beforeEach):true
// ・画面遷移ログ登録時(savePageTransitionLog):false
// ・操作ログ記録時(saveOperationLog):false
let isPageLoading = false
// 遷移元のパス
let fromPath = ''
const getLogData = (params, store, route) => {
  const target_id = []
  if (params?.contractId) { target_id.push(`契約グループID:${params.contractId}`) }
  if (params?.documentId) { target_id.push(`契約書類セットID:${params.documentId}`) }
  if (params?.distributionDocumentId) { target_id.push(`配布書類セットID:${params.distributionDocumentId}`) }
  if (params?.screeningApplicationId) { target_id.push(`審査申込情報ID:${params.screeningApplicationId}`) }
  if (params?.customerInfoId) { target_id.push(`契約者ID:${params.customerInfoId}`) }

  const article_id = params?.articleId ? parseInt(params?.articleId, 10) : null
  const article_name = article_id
    ? store.getters['articles/getArticleByArticleId'](article_id)?.article_name || null
    : null

  return {
    article_id,
    article_name,
    target_id: target_id.join('/') || null,
    operation_result: '',
    note: null,
    url: process.env.BASE_URL + route.path,
  }
}

/**
 * 画面遷移ログ
 * @param {*} app app
 * @param {*} store store
 * @param {*} fromRoute 発生もとのrouteオブジェクト.
 */
const savePageTransitionLog = async (app, store, fromRoute) => {
  // ダッシュボードから物件トップや、物件画面から設定画面に遷移した場合、
  // 遷移もとのcreatedやmountedも実施されていたので、遷移もとの現在のパスと同一の場合は、画面遷移のログを残さない
  if (fromPath === fromRoute.path) {
    return
  }
  isPageLoading = false

  const logData = getLogData(fromRoute.params, store, fromRoute)

  const pageTrasitionLog = {
    ...logData,
    operation_type: '画面遷移',
    operation_result: !app.context._errored ? '成功' : '失敗'
  }

  // console.warn(pageTrasitionLog)

  await store.dispatch('operationLog/saveOperationLog', pageTrasitionLog)
}

// 更新操作（追加、更新、削除、ログインなど）ログ
const saveOperationLog = async (store, optional, operationResult, app) => {
  const route = app.context.route
  const logData = getLogData(route.params, store, route)
  isPageLoading = false
  const operation_result = operationResult === null
    ? null
    : operationResult === true ? '成功' : '失敗'

  const operationLog = {
    ...logData,
    ...optional,
    operation_result,
  }

  // console.warn(operationLog)

  await store.dispatch('operationLog/saveOperationLog', operationLog)
}

export default ({ app, store }, inject) => {
  // beforeEachをフックして、isPageLoadingをtrueにする
  app.router.beforeEach((to, from, next) => {
    isPageLoading = true
    // 遷移元のパスを記録しておく
    fromPath = from.path
    // console.warn('page遷移', fromPath, '->', to.path)
    next()
  })

  inject('isPageLoading', () => { return isPageLoading })
  inject('savePageTransitionLog', (fromRoute) => savePageTransitionLog(app, store, fromRoute))
  inject('saveOperationLog', (optional, operationResult = false) => saveOperationLog(store, optional, operationResult, app))
}
