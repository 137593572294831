export default {
  state: {
    isShow: false,
  },

  getters: {
    getDrawerState: (state) => state.isShow,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
    },
  },

  actions: {
    show({ commit }) {
      const params = {
        isShow: true,
      }
      commit('saveDrawer', params)
    },

    hide({ commit }) {
      const params = {
        isShow: false,
      }

      commit('saveDrawer', params)
    },
  },
}
