export default {
  state: {
    isShow: false,
  },

  getters: {
    getDrawerState: (state) => state.isShow,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },
  },
}
