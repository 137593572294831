
// スクロール位置 初期化
const resetScroll = (contents) => {
  if (contents?.scrollTop) {
    contents.scrollTop = 0
  }
}

export default {
  resetScroll,
}
