
const FILTER_STATUS = {
  ALL: -1,
  NOT_APPROVAL: 0, // 承認なし
  APPROVAL: 1, // 承認あり
}

const FILTER_TEXT = {
  ALL: 'すべて',
  NOT_APPROVAL: '承認なし',
  APPROVAL: '承認あり',
}

export default {
  FILTER: [
    { value: FILTER_STATUS.ALL, text: FILTER_TEXT.ALL },
    { value: FILTER_STATUS.NOT_APPROVAL, text: FILTER_TEXT.NOT_APPROVAL },
    { value: FILTER_STATUS.APPROVAL, text: FILTER_TEXT.APPROVAL },
  ],
  FILTER_STATUS,
  FILTER_TEXT,
}
