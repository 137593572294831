import CONST from '@/data/const'

export default {
  state: {
    isShow: false,
    currentStatus: CONST.CONTRACT_STATUSES.PRE_APPLYING.status,
  },

  getters: {
    getDrawerState: (state) => state,
    getCurrentStatus: (state) => state.currentStatus,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data
    },

    saveCurrentStatus(state, data) {
      state.currentStatus = data
    }
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },

    setCurrentStatus({ commit }, payload) {
      commit('saveCurrentStatus', payload)
    }
  },
}
