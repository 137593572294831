
import { mapGetters, mapActions } from 'vuex'

export default {
  name: 'LayoutsDefault',
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('themeConfig', [
      'getThemeMode',
      // Here you can import other getters from the products.js
    ]),
    getThemeLayout() {
      return this.$store.state.themeConfig.themeMode
    },
  },
  created() {},
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
  methods: {
    ...mapActions({
      changeThemeLayout: 'themeConfig/changeThemeLayout',
      updateTheme: 'themeConfig/updateTheme',
    }),
  },
}
