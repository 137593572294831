// import CONST from '@/data/const'

export default async ({ redirect, store, route }) => {
  // ログインチェック
  // storeにaccount情報があるかどうか
  if (!store.getters['account/isLogined']) {
    // APIからアカウント情報取得
    await store.dispatch('account/fetchAccount')

    // account情報がない場合は、ログイン画面にリダイレクト
    if (!store.getters['account/isLogined']) {
      return redirect('/login')
    }
  }

  // 物件一覧情報を取得済みかどうか
  if (!store.getters['articles/isFetched']) {
    await store.dispatch('articles/fetchAccessibleArticles')
  }
}
