
export default {
  MAX_NEW_TEMPLATE_COUNT: 30,
  CONCLUED: 1, // 締結済
  NOT_CONCLUED: 0, // 未締結
  IS_DELEGATION: 1, // 委任状
  IS_CONTRACT: 2, // 売買契約書

  // step - left menu
  EDIT_STEPS: {
    DOCUMENT_TYPE: {
      TITLE: '書類タイプ',
      STEP: 0,
    },
    APPROVAL: {
      TITLE: '承認グループ',
      STEP: 1,
    },
    PARTNER: {
      TITLE: '関係者',
      STEP: 2,
    },
    DELEGATION_TARGET: {
      TITLE: '委任状対象者',
      STEP: 3,
    },
    CONTRACT_HISTORY: {
      TITLE: '原契約',
      STEP: 4,
    },
    SENDER: {
      TITLE: '送信先',
      STEP: 5,
    },
    FILES: {
      TITLE: '契約書類',
      STEP: 6,
    },
    CONFIRM: {
      TITLE: '確認',
      STEP: 7,
    },
  },

  // 書類作成方法 - right contents row 1
  DOCUMENT_SIGNED_TYPES: [
    {
      value: 0,
      text: '契約未締結',
      description: '未締結の契約書類PDFをアップロードし、電子契約を行うために承認依頼を実行します。',
    },
    {
      value: 1,
      text: '書面捺印済',
      description:
        '書面で締結済の契約書類PDFをアップロードし、ムスベル上で登録・管理をします。（すでに締結済の契約書類のため、電子契約は行うことはできません）',
    },
  ],

  // 書類の種類 - right contents row 3
  DOCUMENT_TYPES: [
    {
      value: 2,
      text: '売買契約書（及び変更の覚書）',
      description: '売買契約書（及び変更の覚書）をセットします。書類を作成した時の情報を用いて原契約情報が保存されます。',
    },
    {
      value: 0,
      text: '覚書（委任状、売買契約書以外）',
      description: '委任状及び売買契約書以外の契約書類をセットします。',
    },
    {
      value: 1,
      text: '委任状',
      description: '委任状をセットします。（代理人のメール認証を行う必要があります）',
    },
  ],

  // ユーザータイプ
  USER_TYPE: {
    CUSTOMER: 0,
    HOME_BUILDER: 1,
    PARTNER: 2,
  }
}
