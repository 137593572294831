export default {
  DEFAULT: {
    page: 1,
    sortBy: [],
    sortDesc: [],
    descending: false,
    searchText: '',
    filterStatus: -1,
    pageCount: -1,
  }
}
