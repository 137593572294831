import CONST from '@/data/const'

// 契約書類プレビュー情報取得
export default {
  state: {
    previewInfo: {},
    previewFile: {},
  },

  getters: {
    getContractFilesPreviewInfo: (state) => {
      return state.previewInfo
    },
  },

  mutations: {
    saveContractFilesPreviewInfo(state, data) {
      state.previewInfo = data
    },

  },

  actions: {
    // 契約書類プレビュー情報取得
    async fetchContractFilesPreviewInfo({ commit }, data) {
      const { contract_info_id, word_template_id } = data.params

      const queryList = []
      let query = ''
      Object.keys(data.query).forEach(key => { data.query[key] && queryList.push(`${key}=${data.query[key]}`) })
      if (queryList.length > 0) {
        query = '?' + queryList.join('&')
      }

      return await this.$axios.get(`/contract_files/preview/info/${contract_info_id}/${word_template_id}${query}`)
        .then((response) => {
          let previewInfo = {}
          if (response.data?.body?.data) {
            previewInfo = response.data.body.data
          }

          commit('saveContractFilesPreviewInfo', previewInfo)
        })
    },

    // 契約書類プレビューPDF変換
    async fetchContractFilesPreviewFile({ commit }, params) {
      const { contract_info_id, word_template_id, data } = params

      return await this.$axios
        .post(
          `/contract_files/preview/file/${contract_info_id}/${word_template_id}`,
          data,
          // メッセージを表示する
          { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.PDF_FILE }
        ).then((response) => {
          return response.data?.body?.data.pdf_converter_id || null
        })
    },

  },
}
