/*
 * 関係者情報ストア
 */

export default {
  state: {
    partner: {},
    articlePartnerList: [],
    partnerList: [],
  },

  getters: {
    getArticlePartnerList: (state) => state.articlePartnerList,
    getPartnerList: (state) => state.partnerList,
    getPartnerDetail: (state) => state.partner,
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveArticlePartnerList(state, data) {
      state.articlePartnerList = data
    },

    savePartnerList(state, data) {
      state.partnerList = data
    },

    savePartner(state, data) {
      state.partner = data
    },

  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // 物件関係者情報一覧取得
    async fetchArticlePartners({ commit }, articleId) {
      const res = await this.$axios.get(`/articles/${articleId}/partners`)
      const articlePartnerList = res.data?.body?.data || []

      commit('saveArticlePartnerList', articlePartnerList.map((item) => {
        item.partner.name = item.partner.name || '' // name が null で返ってきた場合、'' に置き換える
        return item
      }))
    },

    // 関係者情報一覧取得
    async fetchPartners({ commit },) {
      const res = await this.$axios.get('/partners')
      commit('savePartnerList', res.data?.body?.data || [])
    },

    // 関係者情報取得
    async fetchPartnerDetail({ commit }, params) {
      const res = await this.$axios.get(`/partners/${params.partnerId}`)
      const partner = res.data?.body?.data || {}
      commit('savePartner', partner)
      return partner
    },

    // 関係者情報登録
    async addPartner({ commit }, payload) {
      return await this.$axios.post('/partners', payload)
    },

    // 関係者情報更新
    async updatePartner({ commit }, payload) {
      return await this.$axios.put(`/partners/${payload.partners_id}`, payload.form)
    },

    // 関係者情報削除
    async deletePartner({ commit }, payload) {
      return await this.$axios.delete(`/partners/${payload.partners_id}`)
    },

    // 物件関係者情報登録
    async addArticlePartners({ commit }, payload) {
      const { articleId, params } = payload
      return await this.$axios.post(`/articles/${articleId}/partners`, params)
    },

    // 物件関係者情報削除
    async deleteArticlePartner({ commit }, payload) {
      const { articleId, partnerId } = payload
      return await this.$axios.delete(`/articles/${articleId}/partners/${partnerId}`)
    },

  }
}
