
export default {
  state: {
    isShow: false,

  },

  getters: {
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
    },
  },

  actions: {
    showDrawer({ commit }) {
      const params = {
        isShow: true,
      }
      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        isShow: false,
      }

      commit('saveDrawer', params)
    },
  },
}
