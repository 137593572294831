
import { mapGetters } from 'vuex'
export default {
  name: 'CustomerIndex',
  components: {
    BaseView: () => import('./View'),
    AppBar: () => import('./AppBar'),
    ProgressCircle: () => import('@/components/atoms/ProgressCircle'),
    SnackBar: () => import('@/components/atoms/SnackBar'),
    ConfirmDialog: () => import('@/layouts/common/ConfirmDialog'),
  },

  computed: {
    ...mapGetters('themeConfig', ['getThemeMode']),

    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },
  },
  created() {
    this.$store.dispatch('common/setConfirm', this.confirm)
  },
  methods: {
    confirm(params) {
      // params = {title, message}
      return this.$refs.confirmDialog?.open(params)
    },
  },
}
