export default {

  state: {
    article_name: '',
    lot_number: '',
    mail_address: '',
    is_confirmed: false,
    customer_key: '',
    user_type: ''
  },

  getters: {
    getArticleName: (state) => state.article_name,
    getLotNumber: (state) => state.lot_number,
    getMailAddress: (state) => state.mail_address,
    isConfirmed: (state) => state.is_confirmed,
    getCustomerKey: (state) => state.customer_key,
    getUserType: (state) => state.user_type,
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveAuthorization(state, data) {
      state.article_name = data.article_name
      state.lot_number = data.lot_number
      state.mail_address = data.mail_address
      state.is_confirmed = data.is_confirmed
      state.customer_key = data.customer_key
      state.user_type = data.user_type
    },
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchAuthorication({ commit }, payload) {
      const apiUrl = process.env.API_WEBHOOK_URL
      // 認証なしでも叩けるAPIを実行
      await this.$axios.get(
        `${apiUrl}/authorization?cd=${payload.cd}&authorization_token=${payload.authorization_token}`
      ).then(function (response) {
        if (response.data?.body?.data) {
          commit('saveAuthorization', response.data.body.data)
        } else {
          commit('saveAuthorization', {})
        }
      })
    },

    async putAuthorication({ commit }, payload) {
      // authorization_token
      // phone_number
      // cd
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      return await this.$axios.put(
        `${apiUrl}/authorization`,
        payload
      )
    },

    async createCognitoUser({ commit }, payload) {
      // authorization_token
      // mail_address
      // phone_number
      // cd
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      return await this.$axios.post(
        `${apiUrl}/authorization/create_cognito_user`,
        payload)
    },

    async completeMail({ commit }, payload) {
      return await this.$axios.put('/authorization/comp', payload)
    }
  },

}
