/*
 * 物品サービス情報ストア
 */

export default {
  state: {
    goodsServices: [],
  },

  getters: {
    getGoodsServices: (state) => {
      return state.goodsServices
    },
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveGoodsServices(state, data) {
      state.goodsServices = data
    },
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // 物品サービス情報一覧取得
    async fetchGoodsServices({ commit }, params) {
      const { contractId } = params
      await this.$axios.get(`/goods_services/${contractId}`, params).then((response) => {
        let goodsServices = []
        if (response?.data?.body?.data) {
          goodsServices = response.data.body.data
        }
        commit('saveGoodsServices', goodsServices)
      })
    },
    // 物品サービス情報追加
    async addGoodsService({ commit }, payload) {
      const { contractId, form } = payload
      const params = {
        ...form
      }
      return await this.$axios.post(`/goods_services/${contractId}`, params)
    },
    // 物品サービス情報更新
    async updateGoodsService({ commit }, payload) {
      const { id, form } = payload
      const params = {
        ...form

      }
      return await this.$axios.put('/goods_services?goods_service_id=' + id, params)
    },
    // 物品サービス情報削除
    async deleteGoodsService({ commit }, payload) {
      const { id } = payload
      return await this.$axios.delete('/goods_services?goods_service_id=' + id)
    },
  },
}
