/*
 * プリファレンス情報ストア
 */

export default {
  state: {
    preference: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getPreference: state => state.preference,
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    setPreference(state, data) {
      state.preference = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchPreference({ commit }, params) {
      await this.$axios.get('/preference', params).then((response) => {
        commit('setPreference', response.data?.body?.data)
      })
    },

    async updatePreference({ commit }, payload) {
      const { code, params } = payload
      return await this.$axios.put('/preference/' + code, params)
    },
  },
}
