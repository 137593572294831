
const MAX_AGE = 60 * 60 * 24 * 7

const OPTIONS = {
  path: '/',
  maxAge: MAX_AGE,
  sameSite: 'lax',
  secure: !['localstub', 'local', 'localdev'].includes(process.env.ENV),
}

export default {
  MAX_AGE,
  ID_TOKEN: 'idToken',
  ACCESS_TOKEN: 'accessToken',
  REFRESH_TOKEN: 'refreshToken',
  LOGIN_ID: 'login_id',
  TENANT_CODE: 'tenant_code',
  LOGIN_INFO_REMIND: 'login_info_remind',
  OPTIONS,
}
