const EMPTY = {
  id: null,
  article_id: null,
  partner_id: null,
  partner: {
    id: null,
    name: '',
    company_name: '',
    department_name: '',
    company_title: '',
    address: '',
    mail_address: '',
    remarks_1: '',
    remarks_2: '',
    remarks_3: '',
    remarks_4: '',
    remarks_5: '',
    access_code: ''
  },
}

export default {
  state: {
    isShow: false,
    current: EMPTY,
  },

  getters: {
    getDrawerState: (state) => state,
    getCurrnetArticlePartner(state) {
      return state.current
    }
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
    },
    saveCurrentArticlePartner(state, data) {
      state.current = data
    },
  },

  actions: {
    showUpdateDrawer({ commit }, params) {
      params = Object.assign(
        {
          isShow: true,
        },
        params,
      )
      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        isShow: false,
      }

      commit('saveDrawer', params)
    },

    setCurrentArticlePartner({ commit }, params = EMPTY) {
      const newData = Object.assign(EMPTY, params)
      commit('saveCurrentArticlePartner', newData)
    },

  },
}
