
const defaultPagination = {
  page: 1,
  sortBy: [],
  sortDesc: [],
  descending: false,
  searchText: '',
  filterStatus: -1, // すべて
  pageCount: 0,
}

export default {
  state: {
    articleId: null,
    pagination: defaultPagination,
  },

  getters: {
    getPagination: (state) => {
      return state.pagination
    },
  },

  mutations: {
    saveArticleId(state, articleId) {
      if (state.articleId !== articleId) {
        state.pagination = Object.assign({}, defaultPagination)
      }

      state.articleId = articleId
    },

    savePagination(state, data) {
      state.pagination = data
    },
  },

  actions: {
    setArticleId({ commit }, articleId) {
      commit('saveArticleId', articleId)
    },

    setPagination({ commit, state }, payload) {
      commit('savePagination', { ...state.pagination, ...payload })
    },
  },
}
