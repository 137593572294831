import CONST from '../data/const'

const EMPTY_CUSTOMER = {
  id: '',
  contract_info_id: '',
  customer_name: '',
  customer_address: '',
  customer_phone_number: '',
  is_company: 0,
  company_name: '',
  company_title: '',
  customer_status: 1,
}

export default {
  state: {
    isShow: false,
    articleId: null,
    customer: EMPTY_CUSTOMER,
    afterUpdate: null, // update 後のイベント
    showType: CONST.DRAWER_SHOW_TYPE.ON_PAGE // ドロワーの設置場所（デフォルトはページ上）
  },

  getters: {
    getCustomer: (state) => {
      return state.customer
    },
    isShow: (state) => {
      return state.isShow
    },
    getDrawerState: (state) => state,

    execAfterUpdate: (state) => state.afterUpdate
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data.isShow
      state.showType = data.showType || CONST.DRAWER_SHOW_TYPE.ON_PAGE

      // ドロワーを閉じる時のみ、関数をクリアする
      if (!data) {
        state.afterUpdate = null
      }
    },

    saveCustomer(state, data) {
      state.customer = data
    },

    saveAfterUpdate(state, data) {
      state.afterUpdate = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },

    setCustomer({ commit }, payload) {
      commit('saveCustomer', { ...EMPTY_CUSTOMER, ...payload })
    },

    setAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', payload)
    },
    clearAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', null)
    },
  },

}
