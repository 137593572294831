/*
 * 契約拡張情報ストア
 */

export default {
  state: {
    contractExtraInfoList: [],
    contractExtraInfo: {},
  },

  getters: {
    getContractExtraInfoList: (state) => {
      return state.contractExtraInfoList
    },
    getContractExtraInfo: (state) => {
      return state.contractExtraInfo
    },
  },

  mutations: {
    saveContractExtraInfoList(state, data) {
      state.contractExtraInfoList = data
    },
    saveContractExtraInfo(state, data) {
      state.contractExtraInfo = data
    },
  },

  actions: {
    // 契約拡張情報一覧取得
    async fetchContractExtraInfoList({ commit }) {
      const res = await this.$axios.get('/contract_extra_infos')
      commit('saveContractExtraInfoList', res.data?.body?.data || [])
    },

    // 契約拡張情報取得
    async fetchContractExtraInfo({ commit }, id) {
      const res = await this.$axios.get(`/contract_extra_infos/${id}`)
      commit('saveContractExtraInfo', res.data?.body?.data || {})
    },

    // 契約拡張情報更新
    async updateContractExtraInfo({ commit }, params) {
      const { id, payload } = params
      const res = await this.$axios.put(`/contract_extra_infos/${id}`, payload)
      return res
    },

    // 契約拡張情報並び順更新
    async updateContractExtraInfoSort({ commit }, payload) {
      const res = await this.$axios.put('/contract_extra_infos/sort', payload)
      return res
    }
  }
}
