/*
 * サイドメニュー定義
 */
export const MODE_BASE = 'mode_base'
export const items = (id, name, hasOperationRole, hasSetArticle) => {
  const res = [
    {
      group: 'article',
      member: [
        {
          title: name,
          icon: 'mdi-home-city-outline',
          group: 'article',
          to: `/articles/${id}`,
          exact: true,
          isArticleName: true,
        },
      ],
    },
    {
      group: 'contracts',
      subheader: '契約グループ管理',
      member: [
        {
          title: '契約グループ一覧',
          icon: 'mdi-text-box-search-outline',
          group: 'contracts',
          to: `/articles/${id}/contracts`,
        },
        {
          title: '契約グループ追加',
          icon: 'mdi-text-box-plus-outline',
          group: 'contracts',
          to: `/articles/${id}/add/contract`,
        },
      ],
    },
    {
      group: 'customers',
      subheader: '顧客管理',
      member: [
        {
          title: '顧客一覧',
          icon: 'mdi-account-outline',
          group: 'customers',
          to: `/articles/${id}/customers`,
        }
      ]
    },
    {
      group: 'documents',
      subheader: '書類セット管理',
      member: [
        {
          title: '契約書類セット一覧',
          icon: 'mdi-book-search-outline',
          group: 'documents',
          to: `/articles/${id}/documents`,
        },
        {
          title: '配布書類セット一覧',
          icon: 'mdi-file-search-outline',
          group: 'documents',
          to: `/articles/${id}/distribution_documents`,
        },
      ],
    },
  ]

  // アクセス中の物件にSET物件情報が設定されている場合にリンクを追加
  if (hasSetArticle) {
    res.push(
      {
        group: 'loan',
        subheader: '住宅ローン仮審査管理',
        member: [
          {
            title: '住宅ローン仮審査申込一覧',
            icon: 'mdi-bank',
            group: 'loan',
            to: `/articles/${id}/loan_screening_applications`,
          },
        ],
      }
    )
  }

  return res
}
