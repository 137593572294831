import { datadogLogs } from '@datadog/browser-logs'
import { datadogRum } from '@datadog/browser-rum'
/*
フロントログ実装

**エラーが出力されるのは以下の３ケース**
* JSエラーが発生した場合
* エラー画面が表示された場合
* API通信で200以外が返ってきた場合

**出力例について**

* JSエラーが発生した場合(エラーログ)
https://app.datadoghq.com/logs?query=-env%3Atest%20-status%3A%28ok%20OR%20debug%20OR%20info%29&cols=host%2Cservice&event=AQAAAYTNHiHwOMVpgAAAAABBWVROSGlWLUFBQTAwQTRQUzF0ckt3QUs&index=%2A&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1669887366332&to_ts=1669888266332&live=true

* エラー画面が表示された場合(warnログ)
https://app.datadoghq.com/logs?query=-env%3Atest%20-status%3A%28error%20OR%20ok%20OR%20debug%20OR%20info%29&cols=host%2Cservice&event=AQAAAYTNGL9MKBe1RAAAAABBWVROR1RKNUFBRGZuMXN6TFdEclFRQUI&index=%2A&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1669887366332&to_ts=1669888266332&live=true

* API通信で200以外が返ってきた場合(warnログ)
https://app.datadoghq.com/logs?query=-env%3Atest%20-status%3A%28error%20OR%20ok%20OR%20debug%20OR%20info%29&cols=host%2Cservice&event=AQAAAYTNGL53KBe1QwAAAABBWVROR1RKNUFBRGZuMXN6TFdEclFRQUE&index=%2A&messageDisplay=inline&stream_sort=time%2Cdesc&viz=stream&from_ts=1669887366332&to_ts=1669888266332&live=true
*/

if (process.env.DATADOG_CLIENT_TOKEN !== '') {
  // 各種パラメータについては以下を参照すること
  // https://docs.datadoghq.com/ja/logs/log_collection/javascript/#%E5%88%9D%E6%9C%9F%E5%8C%96%E3%83%91%E3%83%A9%E3%83%A1%E3%83%BC%E3%82%BF%E3%83%BC
  datadogLogs.init({
    clientToken: process.env.DATADOG_CLIENT_TOKEN,
    env: process.env.ENV,
    service: 'webFront',
    // console.error ログ、キャッチされない例外、ネットワークエラーは Datadog へ送信するかどうか
    forwardErrorsToLogs: true,
    // "all" または "log" "debug" "info" "warn" "error" の配列
    forwardConsoleLogs: ['error'],
    version: process.env.ENV,
  })

  if (process.env.DATADOG_APPLICATION_ID !== '') {
    // RUM ブラウザモニタリング
    // 各種パラメータについては以下を参照すること
    // https://docs.datadoghq.com/ja/real_user_monitoring/browser/
    datadogRum.init({
      applicationId: process.env.DATADOG_APPLICATION_ID,
      clientToken: process.env.DATADOG_CLIENT_TOKEN,
      site: 'datadoghq.com',
      service: 'webFront',
      env: process.env.ENV,
      version: process.env.ENV,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask',
      allowedTracingUrls: [process.env.API_BASE_URL, process.env.API_WEBHOOK_URL]
    })
    datadogRum.startSessionReplayRecording()
  }
}

// pluginsの中から参照させるため（common.js, commonCustomer.js）
export const datadogLogger = datadogLogs

export const appendCommonMessageContext = (messageContext) => {
  // tenant_codeの付与
  // nullだとdatadogにキーごと表示されなかったので、文字列でnullとしています。
  let tenantCode = 'null'
  // 不動産ユーザーにてログイン中
  if (_store.getters['account/isLogined']) {
    tenantCode = _store.getters['account/getTenantCode']
    // 顧客ユーザーにてログイン中
  } else if (_store.getters['customer/customerAccount/isLogined']) {
    tenantCode = _store.getters['customer/customerAccount/getTenantCode']
  }
  return { ...messageContext, ...{ tenant_code: tenantCode } }
}
let _store = null
export default ({ store }, inject) => {
  _store = store

  inject('datadogLogs', {
    ...datadogLogs,
    ...{
      logger: {
        debug: (message, messageContext) => {
          datadogLogs.logger.debug(message, appendCommonMessageContext(messageContext))
        },
        info: (message, messageContext) => {
          datadogLogs.logger.info(message, appendCommonMessageContext(messageContext))
        },
        warn: (message, messageContext) => {
          datadogLogs.logger.warn(message, appendCommonMessageContext(messageContext))
        },
        error: (message, messageContext) => {
          datadogLogs.logger.error(message, appendCommonMessageContext(messageContext))
        },
      }
    },
  })
}
