/*
 * 原契約情報
 */

export default {
  state: {
    original: [],
  },

  getters: {
    getOriginalContracts: state => state.original,
  },

  mutations: {
    saveOriginalContracts(state, data) {
      state.original = data
    },
  },

  actions: {
    async fetchOriginalContracts({ commit }, params) {
      const { contractId } = params
      await this.$axios.get(`/original_contracts/${contractId}`, params).then((response) => {
        let original = []

        if (response.data?.body?.data) {
          original = response.data.body.data
        }

        commit('saveOriginalContracts', original)
      })
    },
  },
}
