
const ALL_FILTER_STATUS = -1
const CUSTOMER_FILTER_STATUS = 0
const AGENT_FILTER_STATUS = 1

export default {
  ALL_FILTER_STATUS,
  CUSTOMER_FILTER_STATUS,
  ARTICLE_CUSTOMER_FILTER: [
    { text: 'すべて', value: ALL_FILTER_STATUS },
    { text: '契約者(共有者)', value: CUSTOMER_FILTER_STATUS },
    { text: '代理人', value: AGENT_FILTER_STATUS },
  ],
  MAX_SELECT_COUNT: 1000,
}
