export default {
  state: {
    isShow: false,
    msgs: [],
    color: 'success',
  },

  getters: {
    isShow: (state) => state.isShow,
    getMsg: (state) => state.msgs,
    getColor: (state) => state.color,
  },

  mutations: {
    saveMsg(state, data) {
      let messages = []
      if (Array.isArray(data)) {
        messages = data
      } else {
        messages = [data]
      }
      state.msgs = messages
    },
    saveShowHide(state, data) {
      state.isShow = data
    },
    saveColor(state, data) {
      state.color = data
    },
  },

  actions: {
    setMsg({ commit }, msg) {
      commit('saveMsg', msg)
    },
    setShowHide({ commit }, params) {
      commit('saveShowHide', params)
    },
    setColor({ commit }, params) {
      commit('saveColor', params)
    },
    showErrorMsg({ commit }, msg) {
      commit('saveMsg', msg)
      commit('saveColor', 'danger')
      commit('saveShowHide', true)
    },
    showSuccessMsg({ commit }, msg) {
      commit('saveMsg', msg)
      commit('saveColor', 'success')
      commit('saveShowHide', true)
    },
  },
}
