export default {
  state: {
    account: {
      id: 0,
      customer_name: '',
      cognito_customer_name: '',
      customer_mail_address: '',
      agent_name: '',
      cognito_agent_name: '',
      agent_mail_address: '',
      customer_key: '',
      contract: {
        id: 0,
        article_name: '',
        lot_number: ''
      },
      is_agreed: false,
      type: 0,
      tenant_code: '',
    },
  },

  getters: {
    getAccount: (state) => state.account,
    getTenantCode: (state) => state.account?.tenant_code,
    getCustomerKey: (state) => state.account?.customer_key,
    isLogined: (state) =>
      state.account && state.account.customer_mail_address && state.account.customer_mail_address !== '',
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveAccount(state, data) {
      state.account = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchAccount({ commit }) {
      await this.$axios
        .get('/account', { ignoreErrorHandler: true })
        .then(function (response) {
          if (response.data?.body?.data) {
            commit('saveAccount', response.data.body.data)
          } else {
            commit('saveAccount', {})
          }
        })
    },
    async login({ commit }, payload) {
      // postパラメータ
      // ログインユーザ種別:type
      // テナントコード:tenant_code
      // メールアドレス: mail_address
      // ２要素認証タイプ:tfa_method_type
      // アクセストークン:access_token
      // カスタマーキー:customer_key

      // テナントコードが数値となるケースがあったので文字列に変換する
      // MUSUBELL_SHINTIKU_PJ-520
      if (payload.tenant_code && payload.tenant_code !== null) {
        payload.tenant_code = payload.tenant_code.toString()
      }

      return await this.$axios.post('/login', payload).then(function (response) {
        // ユーザー情報が取れてしまった場合も考慮してcustomer_nameの存在チェックも実施する
        if (response.data?.body?.data?.customer_name) {
          commit('saveAccount', response.data.body.data)
        } else {
          commit('saveAccount', {})
        }
        return response
      })
    },
  },
}
