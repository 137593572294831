export default {
  state: {
    isShow: false,
  },

  getters: {
    isShow: (state) => state.isShow,
  },

  actions: {},

  mutations: {
    setShowHide(state, payload) {
      state.isShow = payload
    },
  },
}
