
// import { logger } from '@/plugins/logger'

export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    // 操作ログダウンロード
    async download({ commit }, payload) {
      const { month } = payload
      return await this.$axios.get(`/operation_log?month=${month}`)
    },

    // 操作(API実行)ログ 登録
    async saveOperationLog({ state }, payload) {
      // logger.warn(payload)
      return await this.$axios.post('/operation_log', payload, { ignoreErrorHandler: true, unShownLoadingOverlay: true })
    },

  },
}
