
import { mapActions } from 'vuex'
import CONST from '@/data/const'
export default {
  components: {
    BaseButton: () => import('@/components/atoms/BaseButton'),
    ConfirmDialog: () => import('@/layouts/common/ConfirmDialog'),
  },

  layout: 'blank',

  props: {
    error: {
      type: Object,
      default: () => ({
        statusCode: null,
        message: null,
        path: null,
        messageId: null,
      }),
    },
  },

  data() {
    return {}
  },

  computed: {
    msg() {
      // 500エラーでかつAPIエラーじゃない場合は、[システムエラーが発生しました]を表示する
      if (parseInt(this.error.statusCode, 10) === 500 && !this.error.isApiError) {
        return CONST.MESSAGE.SYSTEM_ERR_0000
      }
      return this.error.message || CONST.MESSAGE.SYSTEM_ERR_0000
    },

    titleClass() {
      // リビジョンアップ時のエラー画面
      if (this.error.messageId === 'SYSTEM_ERR_0006') {
        return 'text-h4'
      }
      return 'text-h2'
    },

    title() {
      // リビジョンアップ時のエラー画面
      if (this.error.messageId === 'SYSTEM_ERR_0006') {
        return 'ページの更新が必要です。'
      }
      return this.error.statusCode
    },

    buttonInfo() {
      if (this.$route.path.startsWith('/customer')) {
        // 顧客ページのエラーは、顧客ページトップへ
        return { label: 'トップへ', click: this.goCustomerToTop }
      }

      const statusCode = parseInt(this.error.statusCode, 10) || null
      if (!statusCode) {
        // statusCode が null, undefined の場合
        return { label: 'ログインへ', click: this.goToLogin }
      }

      const isLogined = this.$store.getters['account/isLogined']

      if (/^\/login$/.test(location.pathname)) {
        // ログイン画面で、エラーの場合
        return { label: 'ログインへ', click: this.reloadLogin }
      } else if (
        statusCode === 401 ||
        (isLogined && statusCode >= 500) ||
        (statusCode === 403 && this.error.messageId === 'API_ERR_1070')
      ) {
        // セッションが無効の場合
        // セッションが有効 かつ statusCode が 500 以上の場合は、ログインへ
        // ログイン画面以外で、IP制限に引っかかった場合
        return { label: 'ログインへ', click: this.goToLogin }
      } else if (isLogined && statusCode < 500) {
        // セッションが有効 かつ statusCode が 500 未満の場合は、ダッシュボードへ
        return { label: 'ダッシュボードへ', click: this.goToDashboard }
      } else {
        // 上記以外の場合
        return { label: 'ログインへ', click: this.goToLogin }
      }
    },
  },

  created() {
    // datadogLogs
    this.$datadogLogs.logger.warn('', {
      ...this.error,
      ...{
        message: `[ErrorPage:${this.error.statusCode}]${this.error.messageId}:${this.error.message}`,
      },
    })
    this.$store.dispatch('common/setConfirm', this.confirm)
  },

  mounted() {
    // 画面遷移時にこの画面を表示された場合、画面遷移のログを残す
    if (this.$isPageLoading()) {
      this.$savePageTransitionLog(this.$route)
    }
  },

  methods: {
    ...mapActions({
      updateTheme: 'themeConfig/updateTheme',
    }),

    async goToLogin() {
      // 401 ループを回避するため、エラーハンドリングを無視する
      await this.$common.logout({ ignoreErrorHandler: true })
    },

    // 共通ダイアログ
    confirm(params) {
      // params = {title, message}
      return this.$refs.confirmDialog?.open(params)
    },

    goToDashboard() {
      // SPAの遷移ではなく、画面読み込みを実施したいので、「this.$router.push」を使用しないこと
      location.href = '/dashboard'
    },

    goCustomerToTop() {
      // 顧客ページの場合は、顧客トップへ
      location.href = `/customer/${this.$route.params.customer_key}/top`
    },

    reloadLogin() {
      location.reload()
    },
  },
}
