const displaySignedUrl = (response, fileName) => {
  window.open(response.data?.body?.data.url, '_blank')
}

export default {

  state: {},

  getters: {},

  mutations: {},

  actions: {
    // 利用規約取得
    async getTos({ commit }, type) {
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      await this.$axios.get(`${apiUrl}/tos?type=${type}`)
        .then(function (res) {
          if (!res.isApiError) {
            displaySignedUrl(res)
          }
        })
    },
  },
}
