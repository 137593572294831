import CONST from '@/data/const'
import utils from '@/data/utils'

// エラーハンドラー
const errorHandler = async (e) => {
  if (e.response?.data instanceof Blob) {
    e.response.data = JSON.parse(await e.response.data.text())
  }
  if (e.response?.data?.error) {
    const error = new Error(CONST.MESSAGE[e.response.data.error.message_id])
    error.response = e.response
    throw error
  }
}

export default {

  state: {
    documents: [],
  },

  getters: {
    getDocuments: (state) => {
      return state.documents
    },
  },

  mutations: {
    saveDocuments(state, data) {
      state.documents = data
    },
  },

  actions: {
    // [顧客]書類情報一覧取得
    async fetchDocuments({ commit }, type = 'concluded') {
      await this.$axios.get(`/customer/contract_documents?type=${type}`)
        .then(function (response) {
          if (response.data?.body?.data) {
            commit('saveDocuments', response.data.body.data)
          } else {
            commit('saveDocuments', [])
          }
        })
    },

    // [顧客]書類ダウンロード
    async downloadPdf({ commit }, params) {
      const { contract_document_id, contract_file_id, file_name } = params
      await this.$axios.get(`/customer/contract_document/file/${contract_document_id}/${contract_file_id}`)
        .then(function (res) {
          if (!res.isApiError) {
            utils.file.downloadUrl(res.data.body.data.url, file_name)
          }
        })
        .catch(errorHandler)
    },

    // [顧客]契約に紐づく書類zip作成依頼
    async createCustomerContractDocuments({ commit }, type = 'concluded') {
      return await this.$axios.post(
        `/customer/contract_documents/file?type=${type}`,
        null,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

    // [顧客]契約に紐づく書類zipダウンロード
    async downloadCustomerContractDocuments({ commit }, params) {
      const type = params?.type || 'concluded'
      const { execution_arn } = params
      return await this.$axios.get(
        `/customer/contract_documents/file?type=${type}&execution_arn=${execution_arn}`,
        // メッセージを表示する
        { unShownLoadingOverlay: true, unShownResponseOverlay: true, showLoadingMessage: CONST.LOADING_MESSAGE.ZIP_FILE }
      )
    },

  },

}
