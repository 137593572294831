
import { mapGetters /* mapActions */ } from 'vuex'
import { items as naviItem, MODE_BASE } from '~/data/navi'
import { settingsAdminItem, settingsItem, MODE_SETTNGS } from '~/data/naviSettings'

export default {
  name: 'CommonSideBar',

  components: {
    NaviList: () => import('@/components/molecules/NaviList'),
    MusubellLogoImg: () => import('@/components/atoms/MusubellLogoImg'),
  },

  props: {
    mode: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      logoLink: '/dashboard',
    }
  },

  computed: {
    ...mapGetters('themeConfig', ['getThemeMode']),
    ...mapGetters({
      article: 'article/getArticle',
    }),

    naviItems() {
      switch (this.mode) {
        case MODE_SETTNGS:
          // 権限で、表示メニューを変更
          return this.$common.isSystemAdmin()
            ? settingsAdminItem // システム管理者
            : settingsItem // 物件管理者
        case MODE_BASE:
          if (this.article) {
            return naviItem(
              this.article.id,
              this.article.article_name,
              this.$common.hasOperationRole(),
              this.$common.hasSetArticle(),
            )
          }
          return naviItem('', '')
        default:
          return []
      }
    },
  },
}
