/*
 * 承認グループ
 */
import CONST from '@/data/const'

const INIT_DATA = {
  id: 0,
  name: '',
  approval_group_member_list: [
    { level: 1, member_list: [] },
  ],
  exist_approving_contract_document: 0,
  user_list: [],
}

export default {
  state: {
    isShow: false,
    current: {}, // 選択中の承認グループ
    mode: CONST.APPROVAL_GROUP.MODE.CONFIRM,
    user_list: [],
  },

  getters: {
    isShow: state => state.isShow,
    getCurrent: state => state.current,
    getDrawerMode: state => state.mode,
    getUserList: state => state.user_list,
  },

  mutations: {
    saveDrawer(state, data) {
      state.isShow = data
    },

    saveCurrent(state, data) {
      state.current = data
    },

    saveMode(state, data) {
      state.mode = data
    },

    saveSelectableUsers(state, data) {
      state.user_list = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },

    closeDrawer({ commit }) {
      commit('saveDrawer', false)
    },

    setCurrent({ commit }, params) {
      commit('saveCurrent', params)
    },

    setDrawerMode({ commit }, params) {
      commit('saveMode', params)
    },

    initCurrent({ commit }) {
      commit('saveCurrent', INIT_DATA)
    },

    // レベル削除
    deleteGroup({ commit, state }, params) {
      const { index, name } = params

      const current = { ...state.current }
      current.approval_group_member_list = current.approval_group_member_list.filter((member, i) => index !== i)

      // レベル１を削除した場合
      if (current.approval_group_member_list.length === 0) {
        current.approval_group_member_list = [...current.approval_group_member_list, { level: 1, member_list: [] }]
      } else {
        // レベルを再設定する
        current.approval_group_member_list = current.approval_group_member_list.map((item, i) => {
          return {
            ...item,
            level: i + 1,
          }
        })
      }

      // 値渡し
      let tempData = JSON.stringify(current) // JSON文字列化
      tempData = JSON.parse(tempData)

      tempData.name = name

      commit('saveCurrent', tempData)
    },

    // レベル追加
    appendEmptyGroup({ commit, state }, params) {
      const { name } = params

      const current = { ...state.current }
      const pushData = {
        level: current.approval_group_member_list.length + 1,
        member_list: []
      }

      // 値渡し
      let tempData = JSON.stringify(current) // JSON文字列化
      tempData = JSON.parse(tempData)

      tempData.name = name
      tempData.approval_group_member_list.push(pushData)

      commit('saveCurrent', tempData)
    },

    // メンバー追加
    appendGroupMember({ commit, state }, params) {
      const { index, newMember, name } = params

      const current = { ...state.current }
      const new_member_list = [...newMember]

      // 値渡し
      let tempData = JSON.stringify(current) // JSON文字列化
      tempData = JSON.parse(tempData)

      tempData.name = name
      tempData.approval_group_member_list[index].member_list = new_member_list

      commit('saveCurrent', tempData)
    },

    // 物件承認グループ選択可能ユーザ情報一覧取得
    async fetchApprovalGroupSelectUser({ commit }, params) {
      const { article_id } = params

      await this.$axios.get(`/article_approval_group/${article_id}/selectable_users`).then((response) => {
        let list = []

        if (response.data?.body?.data) {
          list = response.data.body.data
        }

        commit('saveSelectableUsers', list)
      })
    },

  },

}
