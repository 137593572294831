/*
 * 物品サービスドロワー情報ストア
 */
import CONST from '@/data/const'

const emptyGoodsService = {
  id: 0,
  no: 0,
  row_number: 0,
  goods_name: '',
  product_number: '',
  quantity: null,
  mount_position: '',
  warranty: '',
  manual: '',
  service_classification: '',
  remarks: '',
}
export default {
  state: {
    isShow: false,
    isNew: false,
    contractId: null,
    goodsService: [],
    afterUpdate: null, // update 後のイベント
    showType: CONST.DRAWER_SHOW_TYPE.ON_PAGE // ドロワーの設置場所（デフォルトはページ上）
  },

  getters: {
    isShow: (state) => {
      return state.isShow
    },
    isNewGoodsService: (state) => {
      return state.isNew
    },
    getContractId: (state) => {
      return state.contractId
    },
    getGoodsService: (state) => {
      return state.goodsService
    },
    execAfterUpdate: (state) => state.afterUpdate,
    getDrawerState: (state) => state,
  },

  mutations: {
    saveShowHide(state, payload) {
      state.isShow = payload.isShow
      state.showType = payload.showType || CONST.DRAWER_SHOW_TYPE.ON_PAGE

      // ドロワーを閉じる時のみ、関数をクリアする
      if (!payload) {
        state.afterUpdate = null
      }
    },
    saveIsNew(state, payload) {
      state.isNew = payload
    },
    saveContractId(state, payload) {
      state.contractId = payload
    },
    saveGoodsService(state, payload) {
      state.goodsService = payload
    },
    saveAfterUpdate(state, data) {
      state.afterUpdate = data
    },
  },

  actions: {

    setShowHide({ commit }, payload) {
      commit('saveShowHide', payload)
    },

    setContractId({ commit }, payload) {
      const { contractId } = payload
      commit('saveContractId', contractId)
    },

    showCreate({ commit }, payload) {
      const { isShow, showType, maxId } = payload

      commit('saveShowHide', { isShow, showType })
      commit('saveIsNew', true)

      // 追加時は、[物品サービスNoの最大値+1]を設定
      emptyGoodsService.row_number = maxId + 1
      commit('saveGoodsService', { ...emptyGoodsService })
    },

    show({ commit }, payload) {
      const { isShow, showType, goodsService } = payload
      // 更新、削除時
      commit('saveShowHide', { isShow, showType })
      commit('saveIsNew', false)
      commit('saveGoodsService', goodsService)
    },

    setAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', payload)
    },
    clearAfterUpdate({ commit }, payload) {
      commit('saveAfterUpdate', null)
    },
  },
}
