import CONST from '@/data/const'
export default {
  state: {
    account: {
      id: 0,
      original_user_id: 0,
      user_name: '',
      mail_address: '',
      department_name: '',
      is_system_admin: 0,
      type: 0,
      tenant_code: '',
    },
  },

  getters: {
    getAccount: (state) => state.account,
    getTenantCode: (state) => state.account?.tenant_code,
    isLogined: (state) => state.account && state.account.mail_address && state.account.mail_address !== '',
  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveAccount(state, data) {
      state.account = data
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchAccount({ commit }) {
      await this.$axios
        .get('/account', { ignoreErrorHandler: true })
        .then((response) => {
          if (response.data?.body?.data) {
            commit('saveAccount', response.data.body.data)
          } else {
            commit('saveAccount', {})
          }
        })
        .catch(() => {
          commit('saveAccount', {})
        })
    },

    async login({ commit }, payload) {
      // postパラメータ
      // ログインユーザ種別:type
      // テナントコード:tenant_code
      // メールアドレス: mail_address
      // ２要素認証タイプ:tfa_method_type

      // テナントコードが数値となるケースがあったので文字列に変換する
      // MUSUBELL_SHINTIKU_PJ-520
      payload.tenant_code = payload.tenant_code.toString()
      let responseData = {}

      const res = await this.$axios
        .post('/login', {
          ...{
            type: CONST.ACCOUNT_TYPE.USER,
          },
          ...payload,
        })

      // 顧客情報が取れてしまった場合も考慮してoriginal_user_idの存在チェックも実施する
      if (res.data?.body?.data?.original_user_id) {
        responseData = res.data.body.data
      }

      commit('saveAccount', responseData)

      return res
    },

    async logout({ commit }, payload) {
      commit('saveAccount', {})
      return await this.$axios.put('/logout', null, payload)
    },

    clear({ commit }) {
      commit('saveAccount', {})
    },

    // パスワード設定日時更新
    async passwordChangeDate({ commit }, params) {
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      return await this.$axios.put(`${apiUrl}/password/changed_date`, params)
    },

    // パスワード更新（パスワードを忘れた方へ）
    async passwordConfirmForgot({ commit }, params) {
      // 認証なしでも叩けるAPIを実行
      const apiUrl = process.env.API_WEBHOOK_URL
      return await this.$axios.put(`${apiUrl}/password/confirm_forgot`, params)
    }

  },
}
