import { parse } from 'csv-parse/lib/sync'
import Encoding from 'encoding-japanese'

// CSVの読み込み
const parseCsvFile = async (file) => {
  let records = []

  await new Promise(
    (resolve, reject) => {
      const reader = new FileReader()
      reader.readAsBinaryString(file)

      reader.onload = (e) => {
        try {
          const convertResult = Encoding.convert(e.target.result, 'UNICODE', 'AUTO')
          records = parse(convertResult, { escape: '\\' })
          resolve()
        } catch (error) {
          reject(error)
        }
      }
    }
  )

  return records
}

// CSVのエンコーディングチェック（UTF-8、Shift-JISのみ許可）
const isValidCsvEncode = async (file) => {
  const allowEncoding = ['UTF8', 'SJIS']
  let result = true

  await new Promise(
    (resolve) => {
      const reader = new FileReader()
      reader.readAsBinaryString(file)

      reader.onload = (e) => {
        const detectedEncoding = Encoding.detect(e.target.result)
        // CSVのエンコードが、UTF-8 / Shift-JIS の場合は、true
        result = allowEncoding.includes(detectedEncoding)
        resolve()
      }
    }
  )
  // 問題なし: true: CSVのエンコードが、UTF-8 / Shift-JIS の場合
  // 問題あり: false: CSVのエンコードが、上記以外
  return result
}

export default {
  parseCsvFile,
  isValidCsvEncode,
}
