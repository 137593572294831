
export default {
  MODE: {
    DEFAULT_LIST: 0,
    DOWNLOAD_FILE: 1,
  },
  // 選択上限（チェックボックス）
  MAX_SELECT_COUNT: 200,
  // CSV
  CSV_REGIST: {
    HEADER_COUNT: 2,
    MAX_ITEM_COUNT: 200,
    MAX_ERROR_COUNT: 5,
  },
}
