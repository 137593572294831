import CONST from '../data/const'
export default {
  state: {
    customers: [],
  },

  getters: {
    getCustomers: (state) => state.customers,
    getActiveCustomers: (state) => {
      return state.customers.filter((customer) => {
        // −１が契約解除状態
        return customer.customer_status !== CONST.CUSTOMER_STATUSES.CANCEL.status
      })
    },
    getCustomerIds: (state) => {
      return state.customers.map((customer) => {
        return customer.id
      })
    },
    getCustomerById: (state) => (id) => {
      id = parseInt(id, 10)
      return state.customers.find((customer) => {
        return customer.id === id
      })
    },

  },
  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    setCustomers(state, payload) {
      state.customers = payload
    },
  },
  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    async fetchContractCustomers({ commit }, contractId) {
      await this.$axios.get(`/contract_customers/${contractId}`).then(function (response) {
        if (response?.data?.body?.data) {
          commit('setCustomers', response.data.body.data)
        } else {
          // 0件の場合
          commit('setCustomers', [])
        }
        return response
      })
    },

    // 契約者情報更新
    async updateCustomer({ commit }, payload) {
      const { customer_info_id, params } = payload
      return await this.$axios.put(`/contract_customers/${customer_info_id}`, params)
    },

    // 解約
    async cancelCustomer({ commit }, payload) {
      const { customer_info_id } = payload
      const params = {
        customer_status: -1,
      }
      return await this.$axios.put(`/contract_customers/${customer_info_id}`, params)
    },

    // 解約の取り消し
    async withdrawCancelCustomer({ commit }, payload) {
      const { customer_info_id } = payload
      const params = {
        customer_status: 1,
      }
      return await this.$axios.put(`/contract_customers/${customer_info_id}`, params)
    },

    // 契約者一覧並び替え
    async sortContractCustomers({ commit }, params) {
      const { contract_info_id, id_sort_order } = params
      return await this.$axios.put(`/contract_customers/${contract_info_id}/orders`, { id_sort_order })
    },

  },
}
