// import consola from 'consola'
import CONST from '@/data/const'

const isValidId = (value) => {
  // 半角数字２０文字以内かどうか
  return /^([0-9]{1,20})$/.test(value)
}

export default ({ params, error }) => {
  // paramsにarticleIdが存在する場合は、idチェックを実施
  if ('articleId' in params && !!params.articleId && !isValidId(params.articleId)) {
    // IDの形式が正しくない場合は、
    // [物件情報の取得に失敗しました]を表示する
    error({ statusCode: 404, message: CONST.MESSAGE.API_ERR_1014 })
    return false
  }
  // paramsにcontractIdが存在する場合は、idチェックを実施
  if ('contractId' in params && !!params.contractId && !isValidId(params.contractId)) {
    // IDの形式が正しくない場合は、
    // [契約グループ情報の取得に失敗しました。]を表示する
    error({
      statusCode: 404,
      message: CONST.MESSAGE.API_ERR_1057,
    })
    return false
  }
  // paramsにdocumentIdが存在する場合は、idチェックを実施
  if ('documentId' in params && !!params.documentId && !isValidId(params.documentId)) {
    // IDの形式が正しくない場合は、
    // [契約書類セット情報の取得に失敗しました。]を表示する
    error({
      statusCode: 404,
      message: CONST.MESSAGE.API_ERR_1093,
    })
    return false
  }

  // paramsにdistributionDocumentIdが存在する場合は、idチェックを実施
  if ('distributionDocumentId' in params && !!params.distributionDocumentId && !isValidId(params.distributionDocumentId)) {
    // IDの形式が正しくない場合は、
    // [配布書類セット情報の取得に失敗しました。]を表示する
    error({
      statusCode: 404,
      message: CONST.MESSAGE.API_ERR_1095,
    })
    return false
  }

  // params に screeningApplicationId が存在する場合は、idチェックを実施
  if ('screeningApplicationId' in params && !!params.screeningApplicationId && !isValidId(params.screeningApplicationId)) {
    // IDの形式が正しくない場合は、
    // [仮審査申込情報の取得に失敗しました。]を表示する
    error({
      statusCode: 404,
      message: CONST.MESSAGE.API_ERR_1098,
    })
    return false
  }

  // params に customerInfoId が存在する場合は、idチェックを実施
  if ('customerInfoId' in params && !!params.customerInfoId && !isValidId(params.customerInfoId)) {
    // IDの形式が正しくない場合は、
    // [契約者情報の取得に失敗しました。]を表示する
    error({
      statusCode: 404,
      message: CONST.MESSAGE.API_WARN_1044,
    })
    return false
  }
}
