import { defaultFilter, getObjectValueByPath } from 'vuetify/lib/util/helpers'

// 全角英数字を半角に変換
const _toHalfWidth = (str) => {
  // undefinedやnullの場合は、そのままreturnする
  if (!str) {
    return str
  }
  // number型で入ってくる場合もあったのでtoStringする
  if (typeof str === 'number') {
    str = str.toString()
  }
  try {
    return str.replace(/[Ａ-Ｚａ-ｚ０-９]/g, (s) => {
      return String.fromCharCode(s.charCodeAt(0) - 0xFEE0)
    })
  } catch (e) {
    return str
  }
}

// フィルター処理
const _filter = (value, search, item) => {
  value = _toHalfWidth(value)
  search = _toHalfWidth(search)
  return defaultFilter(value, search, item)
}

// フィルター処理（v-data-iterator）
const customVDataTableFilter = (value, search, item) => {
  // v-data-tableとv-data-iteratorとでは、引数が違うので注意すること
  // v-data-tableの場合は、value, search, item
  // 参考
  // https://github.com/vuetifyjs/vuetify/blob/v2.5.7/packages/vuetify/src/components/VDataTable/VDataTable.ts#L47

  // 値も検索値も全角文字を半角文字に変換する
  return _filter(value, search, item)
}

// フィルター処理（v-data-iterator用、検索キー指定）
const customVDataIteratorFilterBySearchKeys = (items, search, searchKeys) => {
  // v-data-tableとv-data-iteratorとでは、引数が違うので注意すること
  // v-data-iteratorの場合は、items, search, item

  // vuetifyのデフォルトのフィルダー処理から絞り込みキーを選択できるように修正
  // 以下のソースコードから流用
  // https://github.com/vuetifyjs/vuetify/blob/v2.5.7/packages/vuetify/src/util/helpers.ts#L366

  if (!search) { return items }
  search = search.toString().toLowerCase()
  if (search.trim() === '') { return items }

  return items.filter((item) => {
    //
    return Object.keys(item).some((key) => {
      // searchKeysに含まれるものだけを検索対象とする
      if (searchKeys.includes(key)) {
        const result = _filter(getObjectValueByPath(item, key), search, item)
        return result
      }
      return false
    })
  })
}

export default {
  // v-data-iterator用なので注意すること
  customVDataIteratorFilterBySearchKeys,
  // v-data-table用なので注意すること
  customVDataTableFilter
}
