
export default {
  MAX_STEP: 5,
  MODE: {
    CREATE: 'create',
    UPDATE: 'update',
    CONFIRM: 'confirm',
    PROGRESS: 'progress',
    // MODIFY: ['create', 'update', 'confirm'],
    // isModify: (item) => APPROVAL_GROUP_MODE.MODIFY.includes(item),
  },
  APPROVAL_ACTION: {
    APPORVAL: 1,
    REJECT: 2,
  },
  NOT_EXIST_DOCUMENT: 0,
}
