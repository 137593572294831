import { Auth } from 'aws-amplify'
import jwtDecode from 'jwt-decode'
import CONST from '@/data/const'
import { logger } from '@/plugins/logger'
import utils from '@/data/utils'

export default ({ store, app, $cookies }, inject) => {
  // ログアウト処理
  // エラー画面からのログイン遷移の場合は、ignoreErrorHandler: true
  const logout = async (params = { ignoreErrorHandler: false }) => {
    const operationLogParams = {
      operation_type: 'ログアウト',
      article_id: null,
      article_name: null,
      note: null,
      target_id: null,
    }

    // ログアウト成功・失敗関わらず成功ログを出力する（パラメーターはnullを設定）
    await store.$saveOperationLog(operationLogParams, null)

    try {
      // cognitoログアウト処理
      await Auth.signOut({ global: true })
      utils.log.writeCognitoOperationLog('signOut')
    } catch (e) {
      logger.warn(e)
    }

    // APIログアウト処理
    const res = await store.dispatch('account/logout', params)

    if (!res.isApiError) {
      // クッキーのidTokenから、外部IdPのクライアントIDを取得
      const externalIdPCognitoClientId = _getExternalIdPCognitoClientId()
      // クッキーのidTokenを削除
      $cookies.remove(CONST.COOKIE.ID_TOKEN)

      // 外部IdPでログイン中の場合は、ログアウトURLに遷移する
      if (externalIdPCognitoClientId !== null) {
        const REDIRECT_URL = `${process.env.BASE_URL}/login?messageId=API_INFO_1026`
        const ENDPOINT = process.env.COGNITO_LOGOUT_ENDPOINT
        // 画面遷移
        // https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/logout-endpoint.html
        location.href = `https://${ENDPOINT}?client_id=${externalIdPCognitoClientId}&logout_uri=${REDIRECT_URL}`
        return
      }

      // ログインページ（/login）に居る場合は、router.push が動かないためリロードする
      if (location.pathname === '/login') {
        location.reload()
      }

      // ログアウトメッセージ表示
      store.dispatch('snackbar/showSuccessMsg', CONST.MESSAGE.API_INFO_1026)
      // ログイン画面に遷移
      return app.router.push('/login')
    }
  }

  const getCurrentArticleAuthorizationCode = () => {
    // 権限取得
    const account = store.getters['account/getAccount']
    if (parseInt(account.is_system_admin, 10) === 1) {
      // システム管理者
      return CONST.AUTHRIZATIONS.SYSTEM_ADMIN.CODE
    }

    // 現在の物件情報取得
    const currentArticle = store.getters['article/getArticle']
    // 物件情報取得失敗した場合は、nullを返す
    if (!currentArticle) {
      return null
    }

    // 物件管理者かどうかを確認
    // 物件管理者情報(article_manager)
    // article_manager: {
    //   id
    //   original_user_id
    //   user_name
    // }
    if (currentArticle.article_manager_id === account.id) {
      // 物件管理者
      return CONST.AUTHRIZATIONS.ARTICLE_ADMIN.CODE
    }
    // 物件ユーザ情報(article_user)
    // システム管理者及び物件管理者の場合は空JSON
    // article_user: {
    //   id
    //   article_id
    //   user_id
    //   authorization:{
    //     id
    //     authorization_code
    //     authorization_name
    //   }
    // }
    if (currentArticle.article_user && currentArticle.article_user.authorization) {
      // 物件担当者
      return CONST.AUTHRIZATIONS.ARTICLE_OPERATOR.CODE
    }

    return null
  }

  const _getExternalIdPCognitoClientId = () => {
    // 外部IdPでログインしているかどうか
    const idToken = $cookies.get(CONST.COOKIE.ID_TOKEN)
    if (!idToken) {
      return null
    }
    const decodedIdToken = jwtDecode(idToken)
    // 参考
    // https://docs.google.com/spreadsheets/d/1LdAc_SluccAo6HroKwvPoCPCpdRH_1EYx6gHRctTfdM/edit#gid=1177608980&range=A11
    if (decodedIdToken?.identities && decodedIdToken?.aud) {
      return decodedIdToken?.aud
    }
    return null
  }

  const isSystemAdmin = () => {
    // システム管理者かどうか
    const account = store.getters['account/getAccount']
    return parseInt(account.is_system_admin, 10) === 1
  }

  const hasManagedArticle = () => {
    // 物件管理者権限を保有している物件が一つでもあるかどうか
    const accessibleArticles = store.getters['articles/getAccessibleArticles']
    const account = store.getters['account/getAccount']
    return accessibleArticles.some((article) => {
      return article.article_manager_id === account.id
    })
  }

  // 以下アクセス中の物件に対しての権限

  const isArticleAdmin = () => {
    const currentArticle = store.getters['article/getArticle']
    const account = store.getters['account/getAccount']
    // 物件管理者かどうか
    return currentArticle?.article_manager_id === account.id
  }

  const hasOperationRole = () => {
    // 物件担当権限を持っているかどうか
    // システム管理者、及び物件管理者もtrueとなる
    return [
      CONST.AUTHRIZATIONS.SYSTEM_ADMIN.CODE,
      CONST.AUTHRIZATIONS.ARTICLE_ADMIN.CODE,
      CONST.AUTHRIZATIONS.ARTICLE_OPERATOR.CODE,
    ].includes(getCurrentArticleAuthorizationCode())
  }
  const hasApproveRole = () => {
    // 承認権限を持っているかどうか
    // システム管理者、及び物件管理者がtrueとなる
    return [
      CONST.AUTHRIZATIONS.SYSTEM_ADMIN.CODE,
      CONST.AUTHRIZATIONS.ARTICLE_ADMIN.CODE,
    ].includes(getCurrentArticleAuthorizationCode())
  }

  const hasSetArticle = () => {
    // アクセス中の物件にSET物件情報が設定されているかどうか (true:設定/false:未設定)
    const currentArticle = store.getters['article/getArticle']
    return currentArticle.set_article_flag
  }

  inject('common', {
    // ログアウト処理
    logout,
    // 以下権限まわり
    isSystemAdmin,
    hasManagedArticle,
    // 以下のアクセス中の物件に対しての権限
    isArticleAdmin,
    hasOperationRole,
    hasApproveRole,
    // 現在アクセス中の物件に対しての権限コード
    getCurrentArticleAuthorizationCode,
    // アクセス中の物件にSET物件情報が設定されているかどうか
    hasSetArticle,
  })
}
