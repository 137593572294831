/*
 * WES物件情報ストア
 */
export default {
  state: {
    wesArticles: [],
  },

  getters: {
    getWesArticles: (state) => {
      return state.wesArticles
    },
    getWesArticle: (state) => (wesArticleId) => {
      return state.wesArticles.find((wesArticle) => {
        return wesArticle.id === wesArticleId
      })
    },
  },

  mutations: {
    saveWesArticles(state, data) {
      state.wesArticles = data
    },
  },

  actions: {
    // WES物件情報一覧取得
    async fetchWesArticles({ commit }, article_id) {
      const res = await this.$axios.get(`/articles/${article_id}/wes_articles`)
      commit('saveWesArticles', res.data?.body?.data || [])
    },

    // WES物件情報登録
    async addWesArticles({ commit }, payload) {
      return await this.$axios.post(`/articles/${payload.articleId}/wes_articles`, {
        wes_tenant_id: payload.wesTenantId,
        wes_article_code: payload.wesArticleCode,
        wes_musubell_article_name: payload.wesMusubellArticleName
      })
    },

    // WES物件情報更新
    async updateWesArticles({ commit }, payload) {
      return await this.$axios.put(`/articles/${payload.articleId}/wes_articles/${payload.wesArticleInfoId}`, {
        wes_article_code: payload.wesArticleCode,
        wes_musubell_article_name: payload.wesMusubellArticleName
      })
    },

    // WES物件情報削除
    async deleteWesArticles({ commit }, payload) {
      return await this.$axios.delete(`/articles/${payload.articleId}/wes_articles/${payload.wesArticleInfoId}`)
    },

    // WES保証証書一括発行
    async bulkIssuetWesWarranties({ commit }, payload) {
      const { article_id, params, } = payload
      return await this.$axios.post(`/articles/${article_id}/wes_warranties`, params, payload?.config || null)
    },

    // WES保証証書一括終了
    async bulkCloseWesWarranties({ commit }, payload) {
      const { article_id, params, } = payload
      return await this.$axios.put(`/articles/${article_id}/wes_warranties`, params, payload?.config || null)
    },

    // WES保証証書一括交付
    async bulkOpenWesWarranties({ commit }, payload) {
      const { article_id, params, } = payload
      return await this.$axios.put(`/articles/${article_id}/wes_warranties/open`, params, payload?.config || null)
    },

  },
}
