import { logger } from '@/plugins/logger'

export default ({ route, from, store, params }) => {
  const url = {
    from: from.path,
    current: route.path
  }

  logger.info('PATH: middleware/route.js', store, url)

  store.dispatch('common/setHistory', url)
}
