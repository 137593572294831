
import { mapGetters /* mapActions */ } from 'vuex'
export default {
  components: {},
  data() {
    return {}
  },
  computed: {
    ...mapGetters('themeConfig', [
      'getThemeMode',
      // Here you can import other getters from the products.js
    ]),
    getThemeLayout() {
      return this.$store.state.themeConfig.themeMode
    },
  },
  created() {},
  // mounted() {
  //   if (this.$route.params.layout) {
  //     this.changeThemeLayout(this.$route.params.layout);
  //   }
  // },
  methods: {},
}
