
export default {
  state: {
    list: [],
    TagList: []
  },

  getters: {
    /**
     * テンプレート情報取得フェーズごとのリストになっているので注意すること
     * @param {Object} state
     * @returns {list} テンプレート情報リスト
    *  ex)
    *  [
    *    {
    *      phase_code: 1,
    *      template_list: [
    *        {
    *           id: 1,
    *           display_name: '契約書類名',
    *           is_all_article_flg: 1,
    *           use_article: [],
    *           contract_phase: { },
    *           last_upload_user_name: '山田 太郎',
    *           last_upload_date: '2022-10-20T10:00:00',
    *         },
    *         {}...]
    *    },
    *    {
    *      phase_code: 2,
    *      template_list: [{}, {}...]
    *    },
    *    {
    *      phase_code: 2,
    *      template_list: [{}, {}...]
    *    },
    *  ]
    */
    getTemplateList: (state) => {
      return state.list
    },
    getTemplateTagList: (state) => {
      return state.TagList
    },
    /**
     * 指定フェーズのテンプレート情報取得
     * @param {Object} state
     * @returns {list} テンプレート情報
    *  ex)
    *  {
    *    phase_code: 1,
    *    template_list: [
    *      {
    *         id: 1,
    *         display_name: '契約書類名',
    *         is_all_article_flg: 1,
    *         use_article: [],
    *         contract_phase: { },
    *         last_upload_user_name: '山田 太郎',
    *         last_upload_date: '2022-10-20T10:00:00',
    *       },
    *       {}...]
    *  }
    */
    getTemplateByPhaseCode: (state) => (phaseCode) => {
      return state.list.find((item) => item.phase_code === phaseCode)
    },
  },

  mutations: {
    saveTemplateList(state, payload) {
      state.list = payload
    },
    saveTemplateTagList(state, payload) {
      state.TagList = payload
    },
  },

  actions: {
    // 契約テンプレート一覧情報取得
    async fetchTemplateList({ commit }, params) {
      const query = params?.article_id ? `?article_id=${params.article_id}` : ''

      await this.$axios.get(`/contract_templates${query}`, params).then((response) => {
        let list = []
        if (response.data?.body?.data) {
          list = response.data.body.data
        }
        commit('saveTemplateList', list)
      })
    },

    // 契約テンプレート利用物件更新
    async updateUseArticle({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.put(`/contract_templates/${word_template_id}`, params)
    },

    // 契約テンプレート更新
    async updateTemplate({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.put(`/contract_templates/${word_template_id}/info`, params)
    },

    // 契約テンプレート並び順変更
    async sortTemplateList({ commit }, params) {
      return await this.$axios.post('/contract_templates/sort', params)
    },

    // 契約テンプレートファイル取得
    async downloadTemplate({ commit }, payload) {
      const { word_template_id } = payload
      return await this.$axios.get(`/contract_templates/${word_template_id}/file`)
    },

    // 契約テンプレート複製
    async duplicateTemplate({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.post(`/contract_templates/${word_template_id}/duplicate`, params)
    },

    // 契約テンプレート削除
    async deleteTemplate({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.delete(`/contract_templates/${word_template_id}`, params)
    },

    // 契約テンプレートファイルアップロード
    async uploadTemplate({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.put(`/contract_templates/${word_template_id}/file`, params)
    },

    // 契約書類連結PDFダウンロード
    async downloadConcatPdf({ commit }, payload) {
      const { word_template_id } = payload
      return await this.$axios.get(`/contract_templates/${word_template_id}/concat_pdf_file`)
    },

    // 契約書類連結PDF削除
    async deleteConcatPdf({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.delete(`/contract_templates/${word_template_id}/concat_pdf_file`, params)
    },

    // 契約書類連結PDF登録
    async updateConcatPdf({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.post(`/contract_templates/${word_template_id}/concat_pdf_file`, params)
    },

    // 契約書類テンプレート印字タグ一覧取得
    async fetchTemplateTagList({ commit }, payload) {
      const res = await this.$axios.get(`/contract_templates/${payload.word_template_id}/tag_definitions`)
      commit('saveTemplateTagList', res.data?.body?.data || [])
      return res
    },

    // 契約書類テンプレート印字タグ一括更新
    async updateTemplateTag({ commit }, payload) {
      const { word_template_id, params } = payload
      return await this.$axios.put(`/contract_templates/${word_template_id}/tag_definitions`, params)
    },

  },

}
