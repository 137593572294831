export default {
  ARTICLE_OPERATOR: {
    CODE: 2,
    TEXT: '担当者',
    ICON_COLOR_CLASS: 'blue',
    DESCRIPTION: '担当者です。',
  },
  ARTICLE_ADMIN: {
    CODE: 16,
    TEXT: '物件管理者',
    ICON_COLOR_CLASS: 'info',
    DESCRIPTION: '物件管理者です。',
  },
  SYSTEM_ADMIN: {
    CODE: 256,
    TEXT: 'システム管理者',
    ICON_COLOR_CLASS: 'red',
    DESCRIPTION: 'システム管理者です。',
  },
  CUSTOMER_USER: {
    CODE: 16384,
    TEXT: 'カスタマユーザー特別',
    ICON_COLOR_CLASS: 'gray',
    DESCRIPTION: 'カスタマユーザー特別です。',
  },
}
