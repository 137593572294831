
const defaultPagination = {
  page: 1,
  sortBy: [],
  sortDesc: [],
  descending: false,
  searchText: '',
  filterStatus: 99,
  pageCount: 0,
}

export default {
  state: {
    articleId: null,
    contractId: null,
    pagination: defaultPagination,
  },

  getters: {
    getPagination: (state) => state.pagination,
  },

  actions: {
    setContractId({ commit }, payload) {
      commit('saveContractId', { articleId: payload.articleId, contractId: payload.contractId })
    },
    setPagination({ commit, state }, payload) {
      commit('savePagination', { ...state.pagination, ...payload })
    },
    resetPagination({ commit }) {
      commit('savePagination', defaultPagination)
    },
  },

  mutations: {
    saveContractId(state, payload) {
      if (state.articleId !== payload.articleId ||
        state.contractId !== payload.contractId) {
        state.pagination = defaultPagination
      }
      state.articleId = payload.articleId
      state.contractId = payload.contractId
    },

    savePagination(state, data) {
      state.pagination = data
    },
  },
}
