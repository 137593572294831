import { datadogLogger, appendCommonMessageContext } from '@/plugins/datadog'
import { logger } from '@/plugins/logger'
import CONST from '@/data/const'

let _authorization
export const setAuthorization = (authorization) => {
  _authorization = authorization
}

export default ({ $axios, store, error, $cookies }) => {
  // リクエストログ
  $axios.onRequest((config) => {
    // 共通ヘッダー
    logger.start(config)

    // unShownLoadingOverlayがfalse もしくは、指定なし  :ローディングマスクを表示する
    // unShownLoadingOverlayがtrue                   :ローディングマスクを表示しない
    if (!config?.unShownLoadingOverlay) {
      store.dispatch('common/setOverlay', true)
    }

    // showLoadingMessage が true          :メッセージ付きローディングを表示する
    // showLoadingMessage もしくは、指定なし  :メッセージ付きローディングを表示しない
    if (config?.showLoadingMessage) {
      store.dispatch('common/setOverlayWithLoadingMessage', config?.showLoadingMessage)
    }

    if (!config.url.startsWith(process.env.API_WEBHOOK_URL)) {
      config.headers.Authorization = _authorization
    }
  })

  // レスポンスログ
  $axios.onResponse((response) => {
    logger.success(response)

    // unShownResponseOverlay が false もしくは、指定なし  :ローディングマスクを解除する
    // unShownResponseOverlay が true                   :ローディングマスクを解除しない・ローディングの表示を続ける
    if (!response.config?.unShownResponseOverlay) {
      store.dispatch('common/setOverlay', false)
    }
  })

  // エラーログ
  $axios.onError(async (e) => {
    store.dispatch('common/setOverlay', false)

    const status_code = e.response?.status

    // メンテナンス時のアクセス拒否エラー（WAF）: ステータスコード503の場合
    if (status_code === 503) {
      location.href = process.env.MAINTENANCE_PAGE_URL
      return false
    }

    if (e.config?.ignoreErrorHandler) {
      // エラーハンドリングを無視する
      // return false
      return { ...e.response }
    }

    let response_data = e.response?.data
    if (response_data instanceof Blob) {
      if (e.response.data.type?.toLowerCase() === 'application/json') {
        response_data = JSON.parse(await response_data.text())

        // ステータスコードが401、かつ、以下のいずれかの条件を満たす場合は認証エラーとして共通エラー画面に遷移させる
        // ・e.response?.data?.error?.message_id === 'API_WARN_1043'
        //   -> ムスベルAPIで認証情報が無くて401になったかどうか
        if (status_code === 401 && response_data?.error?.message_id === 'API_WARN_1043') {
          error({ statusCode: status_code, message: CONST.MESSAGE.SYSTEM_ERR_0004 })
          return { ...{ isApiError: true }, ...response_data }
        }
      } else {
        return false
      }
    }

    // ステータスコードが401、かつ、以下のいずれかの条件を満たす場合は認証エラーとして共通エラー画面に遷移させる
    // ・e.response?.data?.message === 'The incoming token has expired'
    //   -> ブラウザ放置でタイムアウト
    // ・e.response?.data?.message === 'Unauthorized'
    //   -> APIGatewayで401になったかどうか
    // ・e.response?.data?.error?.message_id === 'API_WARN_1043'
    //   -> ムスベルAPIで認証情報が無くて401になったかどうか
    if (status_code === 401 &&
      (e.response?.data?.message === 'The incoming token has expired' ||
        e.response?.data?.message === 'Unauthorized' ||
        e.response?.data?.error?.message_id === 'API_WARN_1043')
    ) {
      error({ statusCode: status_code, message: CONST.MESSAGE.SYSTEM_ERR_0004 })
      return { ...{ isApiError: true }, ...e.response }
    }

    // メッセージ
    const message_id = response_data && response_data.error ? response_data.error.message_id : ''
    // システムエラーが発生しました。
    let msg = CONST.MESSAGE.SYSTEM_ERR_0000
    if (message_id !== '') {
      msg = CONST.MESSAGE[message_id]
    }

    // message_val が存在する場合はエラーメッセージの後ろに付加する
    const message_val = response_data?.error?.message_val || []
    if (message_val.length > 0) {
      msg += `(${message_val.join(',')})`
    }

    logger.info('case', status_code)
    logger.info('msg', msg)

    // datadogLogs
    // 個人情報をログとして出さないためにresponse_dataは出力しない
    datadogLogger.logger.warn(`[ApiError:${status_code}]${message_id}:${msg}`,
      appendCommonMessageContext({
        api_request: {
          baseURL: e.config.baseURL,
          url: e.config.url,
          method: e.config.method,
          timeout: e.config.timeout,
          headers: e.config.headers,
        }
      })
    )

    const res = { ...{ isApiError: true }, ...e.response }

    switch (status_code) {
      case 400:
      case 401:
      case 409:
        store.dispatch('snackbar/showErrorMsg', msg)
        break
      default:
        error({ statusCode: status_code, message: msg, isApiError: true, messageId: message_id })
    }

    return res
  })

  $axios.defaults.baseURL = process.env.API_BASE_URL
  $axios.defaults.withCredentials = true

  // default content type
  $axios.defaults.headers.common['Content-Type'] = 'application/json'

  // クッキーからIDトークンを取得してセットする
  // 未ログインの場合は、ログイン処理にて正しいトークンをセットされる
  // $axios.defaults.headers.common.Authorization = $cookies.get(CONST.COOKIE.ID_TOKEN)
  setAuthorization($cookies.get(CONST.COOKIE.ID_TOKEN))
}
