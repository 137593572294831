
export default {
  state: {
    isShow: false, // 表示・非表示
    distributionDocumentId: null,
    distributionParticipantList: [],
  },

  getters: {
    isShowDialog: (state) => state.isShow,
    getDistributionDocumentId: (state) => state.distributionDocumentId,
    getDistributionParticipantList: (state) => state.distributionParticipantList,
    getState: state => state,
  },

  mutations: {
    saveShowHide(state, data) {
      state.isShow = data
    },
    saveDistributionDocumentId(state, data) {
      state.distributionDocumentId = data
    },
    saveDistributionParticipantList(state, data) {
      state.distributionParticipantList = data
    },
  },

  actions: {
    setShowHide({ commit }, params) {
      commit('saveShowHide', params)
    },

    setDistributionDocumentId({ commit }, params) {
      commit('saveDistributionDocumentId', params || null)
    },

    setDistributionParticipantList({ commit }, params) {
      commit('saveDistributionParticipantList', params || [])
    },
  }

}
