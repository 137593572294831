/*
 * WESテナント情報ストア
 */
export default {
  state: {
    wesTenants: [],
    wesTenantArticle: {},
  },

  getters: {
    getWesTenants: (state) => {
      return state.wesTenants
    },
    getWesTenantArticle: (state) => {
      return state.wesTenantArticle
    },
  },

  mutations: {
    saveWesTenants(state, data) {
      state.wesTenants = data
    },
    saveWesTenantArticle(state, data) {
      state.wesTenantArticle = data
    },
  },

  actions: {
    // WESテナント情報一覧取得
    async fetchWesTenants({ commit }) {
      const res = await this.$axios.get('/wes_tenants')
      commit('saveWesTenants', res.data?.body?.data || [])
    },

    // WES連携物件情報取得
    async fetchWesTenantArticle({ commit }, params) {
      const { wesTenantId, wesArticleCode } = params
      const config = params?.config || null
      const res = await this.$axios.get(`/wes_tenants/${wesTenantId}/wes_articles/${wesArticleCode}`, config)
      commit('saveWesTenantArticle', res.data?.body?.data || null)
      return res
    },
  },
}
