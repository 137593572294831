/*
 * ============================
 * File: Octavia.kit.js
 * Project: Octavia-Admin
 * File Created: Monday, 13th April 2020 6:18:44 pm
 * Author:UILIB
 * AuthorUrl:https://ui-lib.com/
 * -----
 * Last Modified: Monday, 13th April 2020 7:16:37 pm
 * Modified By: naime hossain (naime.hossain93@gmail.com)
 * -----
 * Copyright 2020 - 2021 UILIB, UILIB
 * ============================
 */
import Vue from 'vue'

// 契約管理・書類管理
Vue.component(
  'Base',
  () => import('~/layouts/base/Index'),
)

// システム設定
Vue.component(
  'SystemConfig',
  () => import('~/layouts/system-config/Index'),
)

// ダッシュボード
Vue.component(
  'NoSidebar',
  () => import('~/layouts/no-sidebar/Index'),
)

// 顧客マイページ
Vue.component(
  'Customer',
  () => import('~/layouts/customer/Index'),
)
