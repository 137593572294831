
export default {
  state: {
    isShow: false,
    articleId: null,
    wesTenantId: null,
    wesArticleInfoId: null,
  },

  getters: {
    isShow: (state) => state.isShow,
    isAdd: (state) => !state.wesArticleInfoId,
    getArticleId: (state) => state.articleId,
    getWesTenantId: (state) => state.wesTenantId,
    getWesArticleInfoId: (state) => state.wesArticleInfoId,
  },

  mutations: {
    saveShow(state, payload) {
      state.isShow = true
      state.articleId = payload.articleId
      state.wesTenantId = payload.wesTenantId
      state.wesArticleInfoId = payload.wesArticleInfoId
    },
    saveHide(state) {
      state.isShow = false
      state.isAdd = false
      state.articleId = null
      state.wesTenantId = null
      state.wesArticleInfoId = null
    },
  },

  actions: {
    show({ commit }, payload) {
      commit('saveShow', payload)
    },
    closeDrawer({ commit }) {
      commit('saveHide')
    },
  },
}
