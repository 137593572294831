
const defaultPagination = {
  page: 1,
  sortBy: [],
  sortDesc: [],
  descending: false,
  searchText: '',
  filterStatus: 99, // すべて
  pageCount: 0,
}

export default {
  state: {
    contractId: null,
    pagination: defaultPagination,
  },

  getters: {
    getPagination: (state) => {
      return state.pagination
    },
  },

  mutations: {
    saveContractId(state, contractId) {
      if (state.contractId !== contractId) {
        state.pagination = defaultPagination
      }

      state.contractId = contractId
    },

    savePagination(state, data) {
      state.pagination = data
    },
  },

  actions: {
    setContractId({ commit }, payload) {
      commit('saveContractId', payload.contractId)
    },

    setPagination({ commit, state }, payload) {
      commit('savePagination', { ...state.pagination, ...payload })
    },

    resetPagination({ commit }) {
      commit('savePagination', defaultPagination)
    },
  },
}
