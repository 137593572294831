
import { mapGetters } from 'vuex'

export default {
  name: 'SystemConfigView',
  components: {
    AppFooter: () => import('@/layouts/common/Footer'),
    // Customizer: () => import("@/layouts/common-drawer/Customizer"),
  },
  computed: {
    ...mapGetters('themeConfig', ['getThemeMode']),
  },
}
