// import { logger } from '@/plugins/logger'
import CONST from '@/data/const'

export default ({ route, from, store, }) => {
  // common dialog
  if (store.getters['common/showConfirm'] !== null) {
    store.getters['common/showConfirm']({ close: true, title: '', message: '' })
  }

  // Drawer
  store.dispatch('homebuilderDrawer/closeDrawer')
  store.dispatch('userDetailDrawer/closeDrawer')
  store.dispatch('articleHomebuildersDrawer/closeDrawer')
  store.dispatch('articleUsersDrawer/closeDrawer')
  // store.dispatch('contructCustomerSortDrawer/closeDrawer')
  store.dispatch('articlePartnerDrawer/closeDrawer')
  store.dispatch('contractExtraInfoSortDrawer/closeDrawer')
  store.dispatch('partnersDrawer/closeDrawer')

  // 画面表示時には必ずドロワーを閉じる
  store.dispatch('contractStatusDrawer/setShowHide', false)
  store.dispatch('customerDrawer/setShowHide', { isShow: false })
  store.dispatch('mailAuthDrawer/setShowHide', false)
  store.dispatch('agentDrawer/setShowHide', false)
  store.dispatch('customerStatusDrawer/setShowHide', false)
  store.dispatch('fipsInfoDrawer/setShowHide', false)
  store.dispatch('fipsArticleDrawer/hide')
  store.dispatch('wesInfoDrawer/setShowHide', false)
  store.dispatch('wesArticleDrawer/closeDrawer')
  store.dispatch('distributionDocumentBulkSendDialog/setShowHide', false)
  store.dispatch('contractExtraInfoDrawer/setShowHide', false)

  // 顧客2要素認証設定
  store.dispatch('customerMfaDrawer/setShowHide', { isShow: false })

  // store.dispatch('contractDetailDrawer/hide'
  store.dispatch('loginUserDrawer/hide')
  store.dispatch('templateDrawer/closeDrawer')
  store.dispatch('templateSortDrawer/closeDrawer')
  store.dispatch('contractDetailDrawer/setShowHide', { isShow: false })
  store.dispatch('approvalGroupDrawer/closeDrawer')
  store.dispatch('goodsServiceDrawer/setShowHide', { isShow: false })
  store.dispatch('agentDetailDrawer/setShowHide', { isShow: false })

  // ファイルプレビューダイアログから呼ばれるドロワー
  store.dispatch('customerDrawer/setShowHide', { isShow: false, showType: CONST.DRAWER_SHOW_TYPE.ON_FILE_PREVIEW })
  store.dispatch('contractDetailDrawer/setShowHide', { isShow: false, showType: CONST.DRAWER_SHOW_TYPE.ON_FILE_PREVIEW })
  store.dispatch('goodsServiceDrawer/setShowHide', { isShow: false, showType: CONST.DRAWER_SHOW_TYPE.ON_FILE_PREVIEW })
  store.dispatch('agentDetailDrawer/setShowHide', { isShow: false, showType: CONST.DRAWER_SHOW_TYPE.ON_FILE_PREVIEW })

  // 契約書類セット作成 ダイアログ
  store.dispatch('documentEditDialog/hide')
  // 配布書類セット作成 ダイアログ
  store.dispatch('distributionDocumentEditDialog/closeDialog')
}
