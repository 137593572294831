
export default {
  state: {},

  getters: {},

  mutations: {},

  actions: {
    // (汎用) アップロード先URL取得
    async getPresignUrl({ commit }, params) {
      const query = `?file_type=${params.file_type}`
      const res = await this.$axios.get(`/file/upload/s3_presigned_url${query}`, params)
      // エラー時は、undefinedを返す
      return res?.data?.body?.data
    },

    // (PDF) アップロード先URL取得
    async getPresignUrlForPdf({ commit }, params) {
      const res = await this.$axios.get('/file/upload/s3_presigned_url?file_type=pdf', params)
      // エラー時は、undefinedを返す
      return res?.data?.body?.data
    },

    // 署名付きURLへアップロード処理
    async uploadPresignUrl({ commit }, params) {
      const { presigned_url, file, headers } = params
      // ヘッダーに Access-Control-Allow-Origin と Authorization が付与されていると 403 SignatureDoesNotMatch が返るため fetch を使用する
      return await fetch(presigned_url, {
        method: 'PUT',
        headers,
        body: file
      })
    }

  },
}
