/*
 * 宅建士情報ストア
 */

export default {
  state: {
    homebuilders: [],
    homebuilder: {},
  },

  getters: {
    getHomebuilders: (state) => {
      return state.homebuilders
    },
  },

  // 同期で状態を変更する（直接stateを変更する）
  mutations: {
    saveHomebuilders(state, data) {
      state.homebuilders = data
    },
    saveCurrentHomebuilder(state, data) {
      state.homebuilder = data
    },
  },

  // 同期/非同期で状態を変更（mutationsを経由してstateを変更する）
  actions: {
    // 宅建士情報一覧取得
    async fetchHomebuilders({ commit }, requestParams) {
      await this.$axios.get('/homebuilder', requestParams).then((response) => {
        let homebuilders = []
        if (response?.data?.body?.data) {
          homebuilders = response.data.body.data
        }
        commit('saveHomebuilders', homebuilders)
      })
    },
    // 物件宅建士情報一覧取得
    async fetchArticleHomebuilders({ commit }, articleId) {
      await this.$axios.get(`/article_homebuilder/${articleId}`).then((response) => {
        let homebuilders
        if (response.data?.body?.data) {
          homebuilders = response.data.body.data.map((data) => {
            // data.homebuilder = {
            //   id:integer,
            //   homebuilder_number:string,
            //   name:string,
            //   email:string,
            // }
            return data.homebuilder
          })
        } else {
          // 0件
          homebuilders = []
        }
        commit('saveHomebuilders', homebuilders)
      })
    },

    async fetchHomebuilderDetail({ commit }, payload) {
      const { id } = payload
      let homebuilder = {}
      await this.$axios.get('/homebuilder/' + id).then((response) => {
        if (response.data?.body?.data) {
          homebuilder = response.data.body.data
        }
        commit('saveCurrentHomebuilder', homebuilder)
      })
      return homebuilder
    },

    async addHomebuilder({ commit }, payload) {
      const { form } = payload
      const params = {
        homebuilders: [
          {
            homebuilder_number: form.homebuilder_number,
            name: form.name,
            mail_address: form.mail_address,
          },
        ],
      }
      return await this.$axios.post('/homebuilder', params)
    },
    async updateHomebuilder({ commit }, payload) {
      const { id, form } = payload
      const params = {
        homebuilder_number: form.homebuilder_number,
        name: form.name,
        mail_address: form.mail_address,
      }
      return await this.$axios.put('/homebuilder/' + id, params)
    },
    async deleteHomebuilder({ commit }, payload) {
      const { id } = payload
      return await this.$axios.delete('/homebuilder/' + id)
    },
  },
}
