
const EMPTY = {
  id: null,
  name: '',
  homebuilder_number: '',
  mail_address: '',
}

export default {
  state: {
    title: '',
    type: '',
    isShow: false,
    current: EMPTY,
  },

  getters: {
    getDrawerState: (state) => state,
    getCurrnetHomebuilder(state) {
      return state.current
    }
  },

  mutations: {
    saveDrawer(state, data) {
      state.type = data.type
      state.title = data.title
      state.isShow = data.isShow
    },
    saveCurrentHomebuilder(state, data) {
      state.current = data
    },
  },

  actions: {
    showUpdateDrawer({ commit }, params) {
      params = Object.assign(
        {
          type: 'update',
          title: '宅建士詳細',
          isShow: true,
        },
        params,
      )
      commit('saveDrawer', params)
    },

    closeDrawer({ commit }) {
      const params = {
        type: '',
        title: '',
        isShow: false,
      }

      commit('saveDrawer', params)
    },

    setCurrentHomebuilder({ commit }, params = EMPTY) {
      const newData = Object.assign(EMPTY, params)
      commit('saveCurrentHomebuilder', newData)
    },

  },
}
