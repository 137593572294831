
export default {
  state: {
    isShow: false,
    isAgent: false,
    customer: {},
  },

  getters: {
    getCustomer: (state) => {
      return state.customer
    },
    isShow: (state) => {
      return state.isShow
    },
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      if (data.isShow !== undefined) {
        state.isShow = data.isShow
      }
      if (data.isAgent !== undefined) {
        state.isAgent = data.isAgent
      }
    },

    saveCustomer(state, data) {
      state.customer = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },

    setCustomer({ commit }, payload) {
      commit('saveCustomer', { ...payload })
    },
  },

}
