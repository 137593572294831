// import consola from 'consola'

const state = {
  url: {
    from: '',
    current: '',
  },
  overlay: false,
  confirm: null,
  loadingMessage: '',
}

const getters = {
  getHistory: state => state.url,
  getCommon: state => state,
  getOverlay: state => state.overlay,
  showConfirm: state => state.confirm,
  getLoadingMessage: state => state.loadingMessage,
}

const mutations = {
  saveHistory(state, data) {
    state.url = data
  },
  saveOverlay(state, data) {
    state.overlay = data
  },
  saveConfirm(state, data) {
    state.confirm = data
  },
  saveLoadingMessage(state, data) {
    state.loadingMessage = data
  },
}

const actions = {
  setHistory({ commit, }, data) {
    commit('saveHistory', data)
  },
  setOverlay({ commit }, data) {
    commit('saveOverlay', data)
    commit('saveLoadingMessage', '')
  },
  setConfirm({ commit }, data) {
    commit('saveConfirm', data)
  },
  setOverlayWithLoadingMessage({ commit }, data) {
    commit('saveLoadingMessage', data)
    commit('saveOverlay', true)
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
}
