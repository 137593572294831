import { Auth } from 'aws-amplify'
import CONST from '@/data/const'
import { logger } from '@/plugins/logger'
import utils from '@/data/utils'

export default ({ store, app }, inject) => {
  // ログアウト処理
  // エラー画面からのログイン遷移の場合は、ignoreErrorHandler: true
  const logout = async (params, logoutParams = { ignoreErrorHandler: false }) => {
    try {
      // cognitoログアウト処理
      await Auth.signOut({ global: true })
      utils.log.writeCognitoOperationLog('signOut')
    } catch (e) {
      logger.warn(e)
    }
    // APIログアウト処理
    const res = await store.dispatch('account/logout', logoutParams)

    if (!res.isApiError) {
      // ログアウトメッセージ表示
      store.dispatch('snackbar/showSuccessMsg', CONST.MESSAGE.API_INFO_1026)

      // ログインURL
      let loginUrl = `/customer/${params.customer_key}/login`
      // URLにアクセストークンがある場合は、アクセストークンありのlogin画面に遷移する
      if ('accessToken' in params) {
        loginUrl += `?at=${params.accessToken}`
      }
      // ログイン画面に遷移
      return app.router.push(loginUrl)
    }
  }

  inject('commonCustomer', {
    // ログアウト処理
    logout,
  })
}
