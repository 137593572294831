import CONST from '~/data/const'

// 代理人設定が完了（有効）しているかどうか
const isAgentSettingCompleted = function (customer) {
  return (
    // 代理人設定がON
    parseInt(customer.is_agent_enable, 10) === 1 &&
    // 委任状締結が完了
    parseInt(customer.agent_delegation_flg, 10) === 1 &&
    // 代理人のメールアドレス認証完了
    parseInt(customer.agent_mail_confirm_flg, 10) === 1
  )
}

// 主契約者の情報を取得
const getMainCustomer = (customerList) => {
  // customer_status が -1 を排除
  const activeCustomerList = customerList.filter(
    (customer) => customer.customer_status !== CONST.CUSTOMER_STATUSES.CANCEL.status,
  )

  // 該当なし
  if (activeCustomerList.length === 0) {
    return {}
  }

  // co_owner_order の、昇順に並替え
  // co_owner_orderが最小の顧客データを返す
  return activeCustomerList.sort((a, b) => a.co_owner_order - b.co_owner_order)[0]
}

// 顧客種別のテキストを返却('主契約者' or '共有者' or 空文字)
const getCustomerTypeText = (customer, customerList) => {
  if (customer.customer_status === CONST.CUSTOMER_STATUSES.CANCEL.status) {
    return ''
  }

  const mainCustomer = getMainCustomer(customerList)
  if (mainCustomer?.id === undefined) {
    return ''
  }

  return customer.id === mainCustomer.id ? '主契約者' : '共有者'
}

// 個人ステータスのテキストを取得(追加予定、削除予定、削除済、契約中)
const getCustomerStatusText = (customer) => {
  const res = Object.values(CONST.CUSTOMER_STATUSES).find((item) => item.status === customer.customer_status)
  return res?.text || ''
}

// 個人ステータスの情報を取得
const getCustomerStatusInfo = (customer_status) => {
  switch (customer_status) {
    case CONST.CUSTOMER_STATUSES.CANCEL.status:
      // -1:削除済
      return CONST.CUSTOMER_STATUSES.CANCEL

    case CONST.CUSTOMER_STATUSES.PRE_IN.status:
      // 2:追加予定
      return CONST.CUSTOMER_STATUSES.PRE_IN

    case CONST.CUSTOMER_STATUSES.PRE_OUT.status:
      // 0:削除予定
      return CONST.CUSTOMER_STATUSES.PRE_OUT

    default:
      // 1:契約中 チップ表示なし
      return {}
  }
}

export default {
  isAgentSettingCompleted,
  getMainCustomer,
  getCustomerTypeText,
  getCustomerStatusText,
  getCustomerStatusInfo
}
