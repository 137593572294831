/*
 * ユーザー情報ストア
 */

export default {
  state: {
    users: [], // 一覧
    user: {}, // 詳細
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getUserList: (state) => {
      return state.users
    },
    getSystemAdmins: (state) => {
      return state.users.filter((user) => {
        return user.is_system_admin
      })
    },
    getCurrentUser: (state) => {
      return state.user
    },
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    saveUserList(state, data) {
      state.users = data
    },
    saveCurrentUser(state, data) {
      state.user = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchUserList({ commit }, params) {
      await this.$axios.get('/users', params).then((response) => {
        let users = []
        if (response.data?.body?.data) {
          users = response.data.body.data
        }
        commit('saveUserList', users)
      })
    },

    async fetchUserDetail({ commit }, params) {
      const { id } = params
      let user = {}
      await this.$axios.get('/users/' + id).then((response) => {
        if (response.data?.body?.data) {
          user = response.data.body.data
        }
        commit('saveCurrentUser', user)
      })
      return user
    },

    // ユーザ情報登録（ドロワーからの登録）
    async addUser({ commit }, payload) {
      const { form } = payload

      const params = {
        original_user_id: form.original_user_id,
        user_name: form.user_name,
        department_name: form.department_name,
        is_system_admin: form.is_system_admin,
        mail_address: form.mail_address,
        update_if_registered: false, // csvからの登録の場合はtrue, ドロワーからの登録の場合はfalse
      }

      return await this.$axios.post('/users', params)
    },

    // ユーザ情報登録（ローディングなし・エラーハンドリングなし）
    // ユーザー一括登録で使用
    async addUserWithoutLoadingOverlay({ commit }, payload) {
      return await this.$axios.post('/users', payload, { unShownLoadingOverlay: true, ignoreErrorHandler: true })
    },

    async updateUser({ commit }, payload) {
      const { id, form } = payload
      return await this.$axios.put('/users/' + id, form)
    },
    async updatePassword({ commit }, payload) {
      const { id, form } = payload
      const params = {
        new_password: form.new_password,
      }
      return await this.$axios.put('/users/' + id, params)
    },
    async deleteUser({ commit }, payload) {
      const { id } = payload
      return await this.$axios.delete('/users/' + id)
    },

    // ユーザー情報CSV出力データ取得
    async downloadCsvOutputUserInfo({ commit }, params) {
      let query = ''
      if (params?.user_id_list) {
        query = `?user_id_list=${params?.user_id_list}`
      }

      // フォーマットのダウンロードの場合は、クエリが''
      return await this.$axios.get(`/users/csv_output_data${query}`, { responseType: 'blob' })
    },

    // 仮パスワード再送信
    async resendTemporaryPassword({ commit }, params) {
      const { user_id, mail_address } = params
      // フォーマットのダウンロードの場合は、クエリが''
      return await this.$axios.post(`/users/${user_id}/temporary_password`, { mail_address })
    },

  },
}
