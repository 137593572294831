
export default {

  state: {
    isShow: false,
    context: null
  },

  getters: {
    isShow: (state) => {
      return state.isShow
    },

    getContext: (state) => {
      return state.context
    }
  },

  mutations: {
    saveShowHide(state, payload) {
      state.isShow = payload
    },

    saveContext(state, payload) {
      state.context = payload
    },
  },

  actions: {
    show({ commit }, context) {
      commit('saveShowHide', true)
      commit('saveContext', context)
    },

    closeDrawer({ commit }) {
      commit('saveShowHide', false)
    },
  },
}
