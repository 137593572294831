/*
 * リビジョンチェック
 * リリース前後でブラウザを表示し続けた場合を考慮して、HTML/JSが古い場合はエラー画面に遷移させ、その後に画面再読み込みを実施する
 *
 * ビルド時に：revision.jsonをdist配下に作成
 * ex revision.json
 * {"version":"db1e703f-dab4-400a-9f8c-e02399cc7061"}
 *
 * 定期的に上記JSONを読み込み、メモリ内のバージョンと差異がある場合は、エラー画面に遷移
 * ボタン押下で画面再読み込み
 *
 * ※ローカル環境では「revision.json」取得に失敗するので、動作させない
*/
import CONST from '@/data/const'

const INTERVAL_SEC = 30
const revisionJsonUrl = '/revision.json'
// ローカル開発時の動作検証の場合は、以下のdev環境のURLを参照すること
// const revisionJsonUrl = 'https://saas-dev.musubell.com/revision.json'

async function getRevision() {
  try {
    // ブラウザキャッシュを防ぐためにゲットパラメータを追加してアクセスする
    const res = await fetch(`${revisionJsonUrl}?t=${Date.now()}`)
    const revision = await res.json()
    return revision?.version
  } catch (e) {
    // メンテナンス中は、WAFのルールにより、
    // https://maintenance.saas-dev.musubell.com/
    // に301リダイレクトされて、CORSエラーとなる
    return null
  }
}

export default async ({ error }) => {
  const _exec = async function () {
    const newRevisiton = await getRevision()
    // リビジョン番号が更新されている場合は、エラー画面に遷移する
    // リビジョン番号の取得失敗時(null)のユーザー側のネットワーク不調の場合もあるので、エラー画面線に遷移しない
    if (newRevisiton !== null && revisionVer !== newRevisiton) {
      error({ statusCode: 200, message: CONST.MESSAGE.SYSTEM_ERR_0006, messageId: 'SYSTEM_ERR_0006' })
      return
    }

    // リビジョンが同じ場合は、間隔をあけて再度チェックする
    setTimeout(() => {
      _exec()
    }, INTERVAL_SEC * 1000)
  }

  // ローカル環境ではチェックしない
  if (['localstub', 'local', 'localdev'].includes(process.env.ENV)) {
    return
  }

  // バージョン取得
  const revisionVer = await getRevision()

  setTimeout(() => {
    _exec()
  }, INTERVAL_SEC * 1000)
}
