/*
 * 物件ユーザー情報ストア
 */
import CONST from '@/data/const'

export default {
  state: {
    users: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getUsers: (state) => {
      return state.users
    },
    getUser: (state) => (id) => {
      return state.users.find((user) => { return user.id === id })
    },
    getArticleOperatorUsers(state) {
      // 物件担当者一覧
      return state.users.filter((user) => {
        return user.authorization.authorization_code === CONST.AUTHRIZATIONS.ARTICLE_OPERATOR.CODE
      })
    },
  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    saveUsers(state, data) {
      state.users = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchArticleUsers({ commit }, articleId) {
      await this.$axios.get(`/article_users/${articleId}`).then((response) => {
        let users
        if (response.data?.body?.data) {
          users = response.data.body.data.map((data) => {
            // data.user = {
            //   id : integer
            //   original_user_id: string
            //   user_name: string
            //   mail_address: string
            //   department_name: string
            //   is_system_admin: integer
            // }

            // data.authorization = {
            //  id:integer
            //  authorization_code:integer
            //  authorization_name:string
            // }

            // 以下の形式に加工
            // {
            //   id : integer
            //   original_user_id: string
            //   user_name: string
            //   mail_address: string
            //   department_name: string
            //   is_system_admin: integer
            //   authorization = {
            //     id:integer
            //     authorization_code:integer
            //     authorization_name:string
            //   }
            // }
            data.user.authorization = data.authorization

            return data.user
          })
        } else {
          // 0件
          users = []
        }
        commit('saveUsers', users)
      })
    },

    async addArticleUser({ commit }, params) {
      const { article_id, article_users } = params
      return await this.$axios.post(`/article_users/${article_id}`, { article_users })
    },

    async deleteArticleUser({ commit }, params) {
      const { article_id, user_id } = params
      return await this.$axios.delete(`/article_users/${article_id}/${user_id}`)
    },
  },
}
