
export default {
  state: {
    setScreeningApplications: [],
    setScreeningApplicationDetail: {},
    setScreeningApplicationsByCustomerInfoId: [],
  },

  getters: {
    getSetScreeningApplications: (state) => state.setScreeningApplications,
    getSetScreeningApplicationDetail: (state) => state.setScreeningApplicationDetail,
    getSetScreeningApplicationsByCustomerInfoId: (state) => state.setScreeningApplicationsByCustomerInfoId,
  },

  mutations: {
    saveSetScreeningApplications(state, data) {
      state.setScreeningApplications = data
    },

    saveSetScreeningApplicationDetail(state, data) {
      state.setScreeningApplicationDetail = data
    },

    saveSetScreeningApplicationsByCustomerInfoId(state, data) {
      state.setScreeningApplicationsByCustomerInfoId = data
    },

  },

  actions: {
    // SET審査申込情報一覧取得
    async fetchSetScreeningApplications({ commit }, params) {
      const { articleId } = params
      const res = await this.$axios.get(`/articles/${articleId}/set_screening_applications`)
      commit('saveSetScreeningApplications', res.data?.body?.data || [])
    },

    // SET審査申込情報取得
    async fetchSetScreeningApplicationDetail({ commit }, params) {
      const { articleId, screeningApplicationId } = params
      const res = await this.$axios.get(`/articles/${articleId}/set_screening_applications/${screeningApplicationId}`)

      commit('saveSetScreeningApplicationDetail', res.data?.body?.data || {})
    },

    // SET審査申込紐付け情報更新
    async updateSetScreeningApplicationLinks({ commit }, payload) {
      const { articleId, screeningApplicationId, params } = payload
      return await this.$axios.put(`/articles/${articleId}/set_screening_applications/${screeningApplicationId}/links`, params)
    },

    // SET審査申込情報一覧取得(顧客指定)
    async fetchSetScreeningApplicationByCustomerInfoId({ commit }, params) {
      const { articleId, customerInfoId } = params
      const res = await this.$axios.get(`/articles/${articleId}/customers/${customerInfoId}/set_screening_applications`)
      commit('saveSetScreeningApplicationsByCustomerInfoId', res.data?.body?.data || [])
      return res
    },
  },
}
