
import { mapGetters } from 'vuex'

export default {
  name: 'CustomerView',
  components: {
    AppFooter: () => import('@/layouts/common/Footer'),
  },
  computed: {
    ...mapGetters('themeConfig', ['getThemeMode']),
  },
}
