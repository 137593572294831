// 0 一時保存
// 1 承認依頼中
// 2 承認差戻
// 3 承認済
// 4 送信取消
// 5 送信済み
// 6 一時保存
// 7 署名中
// 8 署名済
// 9 署名却下
// 21 書面捺印済
// 24 署名済
// 25 署名却下

export default {
  STATUS_UNCONFIRMED: {
    status: 0,
    text: '一時保存',
    iconColorClass: 'grey lighten-4',
    baseColorClass: 'grey lighten-1',
    colorId: '#BDBDBD',
  },
  STATUS_APPROVAL_REQUEST: {
    status: 1,
    text: '承認依頼中',
    iconColorClass: 'cyan lighten-4',
    baseColorClass: 'cyan lighten-1',
    colorId: '#26C6DA',
  },
  STATUS_APPROVAL_SENDBACK: {
    status: 2,
    text: '承認差戻',
    iconColorClass: 'pink lighten-4',
    baseColorClass: 'pink lighten-1',
    colorId: '#EC407A',
  },
  STATUS_APPROVAL_CONFIRMED: {
    status: 3,
    text: '承認済',
    iconColorClass: 'blue lighten-4',
    baseColorClass: 'blue lighten-1',
    colorId: '#42A5F5',
  },
  // {
  //   status: 5,
  //   text: '送信済み',
  //   iconColorClass: 'red lighten-4',
  // },
  // {
  //   status: 6,
  //   text: '一時保存',
  //   iconColorClass: 'green lighten-4',
  // },
  STATUS_CS_VERIFICATING: {
    status: 7,
    text: '署名中',
    iconColorClass: 'teal lighten-4',
    baseColorClass: 'teal lighten-1',
    colorId: '#26A69A',
  },
  STATUS_SEND_CANCEL: {
    status: 4,
    text: '取消',
    iconColorClass: 'grey lighten-4',
    baseColorClass: 'grey lighten-1',
    colorId: '#BDBDBD',
  },
  STATUS_CS_REJECTED: {
    status: 9,
    text: '署名却下',
    iconColorClass: 'pink lighten-4',
    baseColorClass: 'pink lighten-1',
    colorId: '#EC407A',
  },
  STATUS_CS_CONFIRMED: {
    status: 8,
    text: '署名済',
    iconColorClass: 'green lighten-4',
    baseColorClass: 'green lighten-1',
    colorId: '#66BB6A',
  },
  STATUS_FL_ALREADY_SENDED: {
    status: 21,
    text: '書面捺印済',
    iconColorClass: 'green lighten-4',
    baseColorClass: 'green lighten-1',
    colorId: '#66BB6A',
  },
}

// 署名依頼処理中
export const DOCUMENT_STATUSES_VERIFICATION_PROCESSING = {
  status: 6,
  text: '署名依頼処理中',
}
