import { datadogLogger, appendCommonMessageContext } from '@/plugins/datadog'

// cognito ログ出力用
const writeCognitoOperationLog = (operation, params) => {
  datadogLogger.logger.info(`[cognito operation: ${operation}]`, appendCommonMessageContext(params))
}

export default {
  writeCognitoOperationLog,
}
