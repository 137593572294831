
export default {
  state: {
    selected: [],
  },

  getters: {
    getSelected: (state) => state.selected,
  },

  mutations: {
    saveSelected(state, data) {
      state.selected = data
    },
  },

  actions: {
    initSelected({ commit }) {
      commit('saveSelected', [])
    },
    setSelected({ commit }, payload) {
      commit('saveSelected', payload)
    },
  },
}
