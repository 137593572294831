
export default {
  state: {
    isShow: true,
    articleId: null,
    fipsArticleId: null
  },

  getters: {
    isShow: (state) => state.isShow,
    isAdd: (state) => state.fipsArticleId === null,
    getArticleId: (state) => state.articleId,
    getFipsArticleId: (state) => state.fipsArticleId,
  },

  mutations: {
    saveShow(state, payload) {
      const { articleId, fipsArticleId } = payload
      state.isShow = true
      state.articleId = articleId || null
      state.fipsArticleId = fipsArticleId || null
    },
    saveHide(state) {
      state.isShow = false
      state.fipsArticleId = null
      state.isAdd = false
    },
  },

  actions: {
    show({ commit }, payload) {
      commit('saveShow', payload)
    },
    hide({ commit }) {
      commit('saveHide')
    },
  },
}
