import moment from 'moment'
import { datadogLogger, appendCommonMessageContext } from '@/plugins/datadog'
import MESSAGE from '@/data/constMessage'

// 最大文字数 エラーメッセージ
const maxMessage = (number) => {
  switch (number) {
    case 2000:
      return MESSAGE.INPUT_ERR_1014 // 文字
    case 100:
      return MESSAGE.INPUT_ERR_1003 // 文字
    case 80:
      return MESSAGE.INPUT_ERR_1019 // 文字
    case 64:
      return MESSAGE.INPUT_ERR_1006 // 文字
    case 50:
      return MESSAGE.INPUT_ERR_1001 // 文字
    case 48:
      return MESSAGE.INPUT_ERR_1012 // 文字
    case 40:
      return MESSAGE.INPUT_ERR_1024 // 文字
    case 35:
      return MESSAGE.INPUT_ERR_1027 // 文字
    case 30:
      return MESSAGE.INPUT_ERR_1026 // 文字
    case 25:
      return MESSAGE.INPUT_ERR_1022 // 文字
    case 20:
      return MESSAGE.INPUT_ERR_1002 // 文字
    case 15:
      return MESSAGE.INPUT_ERR_1017 // 桁
    case 10:
      return MESSAGE.INPUT_ERR_1018 // 文字
    case 6:
      return MESSAGE.INPUT_ERR_1025 // 文字
    default:
      return ''
  }
}

export default {
  BASE(params) {
    const { max, required, } = params
    const checkList = []

    if (required) {
      checkList.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    if (max) {
      checkList.push((value) => (value + '').length <= max || maxMessage(max))
    }

    return checkList
  },

  FLOAT(params) {
    const { max, required, } = params
    const checkList = []

    if (required) {
      checkList.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    if (max) {
      checkList.push((value) => (value + '').length <= max || maxMessage(max))
    }

    checkList.push((value) => !value || /^(-|)[0-9]+(\.?[0-9]+|[0-9]*)$/.test(value) || MESSAGE.INPUT_ERR_5018)

    return checkList
  },

  INTEGER(params) {
    const { max, required, } = params
    const isNotZero = params?.isNotZero || false
    const checkList = []

    // 必須
    if (required) {
      // 数字０は許可・未入力の場合は、拒否
      checkList.push((value) => value === 0 ? true : !!value || MESSAGE.INPUT_ERR_1000)
    }

    // 整数
    checkList.push((value) => !value || /^[0-9]+$/.test(value) || MESSAGE.INPUT_ERR_5003,)

    // 最大
    if (max) {
      checkList.push((value) => (value + '').length <= max || maxMessage(max))
    }

    // ０を許容するか
    if (isNotZero) {
      checkList.push((value) => parseInt(value, 10) !== 0 || MESSAGE.INPUT_ERR_1007)
    }

    return checkList
  },

  // 売買代金
  CONTRACT_PRICE: [
    (value) => (value + '').length <= 10 || MESSAGE.INPUT_ERR_1018,
    (value) => !value || /^[0-9]+$/.test(value) || MESSAGE.INPUT_ERR_5003,
  ],

  // 個数
  GOODS_QUANTITY: [
    (value) => (value + '').length <= 9 || MESSAGE.INPUT_ERR_1015, // 桁
    (value) => !value || /^[0-9]+$/.test(value) || MESSAGE.INPUT_ERR_5003,
  ],
  // パスワードリセット認証コード
  PASSWORD_RESET_CODE: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (value + '').length === 6 || MESSAGE.INPUT_ERR_5008,
    (value) => /^[0-9]+$/.test(value) || MESSAGE.INPUT_ERR_5008,
  ],

  // FIPS物件番号
  FIPS_ARTICLE_CODE: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (value + '').length === 6 || MESSAGE.INPUT_ERR_5008,
    (value) => /^[0-9]+$/.test(value) || MESSAGE.INPUT_ERR_5008,
  ],

  // メールアドレス
  EMAIL(required = true) {
    const max = 128
    const max_domain = 67
    const max_user = 64 // ユーザー部分（ローカル部分）の最大文字数

    // メールアドレスを user / domain 部分に切り分け
    const spliteAddress = (value) => {
      return { user: value.split('@')[0] || '', domain: value.split('@')[1] || '' }
    }

    // ドット使用禁止箇所への入力
    const checkDotPosition = (value) => {
      const { user, domain } = spliteAddress(value)
      return user.startsWith('.') || user.endsWith('.') || domain.startsWith('.') || domain.endsWith('.')
    }

    // ハイフン使用禁止箇所への入力
    const checkHyphenPosition = (value) => {
      const { domain } = spliteAddress(value)
      return domain.startsWith('-') || domain.endsWith('-')
    }

    // ユーザー部の使用不可記号の入力
    const checkUserChar = (value) => {
      const { user } = spliteAddress(value)
      return /[^0-9a-zA-Z!#$%&'*+\-/=?^_`{|}~.]/.test(user)
    }

    // ドメイン部の使用不可記号の入力
    const checkDomainChar = (value) => {
      const { domain } = spliteAddress(value)
      return /[^0-9a-zA-Z-.]/.test(domain)
    }

    // ドメイン部のハイフン連続使用
    const checkDomainConsecutiveHyphen = (value) => {
      const { domain } = spliteAddress(value)
      return domain.at(2) === '-' && domain.at(3) === '-'
    }

    // ドメイン部の文字数
    const checkDomainLength = (value) => {
      const { domain } = spliteAddress(value)
      return domain.length > max_domain
    }

    // ユーザー部分（ローカル部分）の文字数チェック
    const checkUserLength = (value) => {
      const { user } = spliteAddress(value)
      return user.length > max_user
    }

    // ドメイン部の最後の「.(ドット)」直後への数字の入力
    const checkDomainNumber = (value) => {
      const { domain } = spliteAddress(value)

      // ドメイン部が正常な値か判定
      if (domain === undefined || domain === null) {
        datadogLogger.logger.warn('[checkDomainNumber] undefined', appendCommonMessageContext({ domain }))
        return false
      }

      // ドメイン部を「.」で分割した配列であるか判定
      if (Array.isArray(domain.split('.')) === false) {
        datadogLogger.logger.warn('[checkDomainNumber] not array', appendCommonMessageContext({ domain }))
        return false
      }

      const lastDomainChunk = domain.split('.').pop()

      if (lastDomainChunk === undefined) {
        return false
      }

      // ドメイン部を「.」で分割した配列の最後の要素の先頭文字が整数の場合（バリデーションエラー）はdatadogログを出力する
      const isInteger = Number.isInteger(parseInt(lastDomainChunk.charAt(0), 10))
      if (isInteger) {
        datadogLogger.logger.warn('[checkDomainNumber] is integer', appendCommonMessageContext({ domain }))
      }

      return isInteger
    }

    if (required) {
      // 必須入力
      return [
        (value) => !!value || MESSAGE.INPUT_ERR_1000,
        (value) => (!!value && (value + '').length <= max) || MESSAGE.INPUT_ERR_1020,
        (value) => (!!value && /^[!-~]+@[!-~]+\.[!-~]+$/.test(value)) || MESSAGE.INPUT_ERR_5002,
        (value) => (!!value && !/\.\./.test(value)) || MESSAGE.INPUT_ERR_5019,
        (value) => (!!value && (value.match(/@/g) || []).length === 1) || MESSAGE.INPUT_ERR_5020,
        (value) => (!!value && !checkDotPosition(value)) || MESSAGE.INPUT_ERR_5021,
        (value) => (!!value && !checkHyphenPosition(value)) || MESSAGE.INPUT_ERR_5022,
        (value) => (!!value && !checkUserChar(value)) || MESSAGE.INPUT_ERR_5023,
        (value) => (!!value && !checkDomainChar(value)) || MESSAGE.INPUT_ERR_5024,
        (value) => (!!value && !checkDomainConsecutiveHyphen(value)) || MESSAGE.INPUT_ERR_5025,
        (value) => (!!value && !checkDomainLength(value)) || MESSAGE.INPUT_ERR_5026,
        (value) => (!!value && !checkUserLength(value)) || MESSAGE.INPUT_ERR_5030, // ユーザー部分（ローカル部分）の文字数チェック
        (value) => (!!value && !checkDomainNumber(value)) || MESSAGE.INPUT_ERR_5027,
      ]
    } else {
      // 任意入力（必須チェック以外のルールの内容は同じ）
      return [
        (value) => value ? (value + '').length <= max || MESSAGE.INPUT_ERR_1020 : true,
        (value) => value ? /^[!-~]+@[!-~]+\.[!-~]+$/.test(value) || MESSAGE.INPUT_ERR_5002 : true,
        (value) => value ? !/\.\./.test(value) || MESSAGE.INPUT_ERR_5019 : true,
        (value) => value ? (value.match(/@/g) || []).length === 1 || MESSAGE.INPUT_ERR_5020 : true,
        (value) => value ? !checkDotPosition(value) || MESSAGE.INPUT_ERR_5021 : true,
        (value) => value ? !checkHyphenPosition(value) || MESSAGE.INPUT_ERR_5022 : true,
        (value) => value ? !checkUserChar(value) || MESSAGE.INPUT_ERR_5023 : true,
        (value) => value ? !checkDomainChar(value) || MESSAGE.INPUT_ERR_5024 : true,
        (value) => value ? !checkDomainConsecutiveHyphen(value) || MESSAGE.INPUT_ERR_5025 : true,
        (value) => value ? !checkDomainLength(value) || MESSAGE.INPUT_ERR_5026 : true,
        (value) => value ? !checkUserLength(value) || MESSAGE.INPUT_ERR_5030 : true, // ユーザー部分（ローカル部分）の文字数チェック
        (value) => value ? !checkDomainNumber(value) || MESSAGE.INPUT_ERR_5027 : true,
      ]
    }
  },

  HOMEBUILDER_NAME: [
    (value) => (!!value && (value + '').trim().length >= 1) || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 64) || MESSAGE.INPUT_ERR_1006,
  ],

  HOMEBUILDER_NUMBER() {
    const numberCountCheck = (value, count) => {
      const check = value.replace(/[0-9][0-9][0-9][0-9]/g, '') // 連続した4文字以上の半角数字
      return value.length - check.length >= count
    }

    return [
      (value) => !!value || MESSAGE.INPUT_ERR_1000,
      (value) => (!!value && (value + '').length <= 20) || MESSAGE.INPUT_ERR_1002,
      (value) => (!!value && numberCountCheck(value, 4)) || MESSAGE.INPUT_ERR_5004,
    ]
  },

  // 携帯電話下４桁
  PHONE_LAST4: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 4) || MESSAGE.INPUT_ERR_5006,
    (value) => (!!value && /^([0-9]{4})$/.test(value)) || MESSAGE.INPUT_ERR_5006,
  ],

  PREFERENCE_NUMBER: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && isNaN(value) === false) || MESSAGE.INPUT_ERR_5003,
    (value) => (!!value && (value + '').length <= 3) || MESSAGE.INPUT_ERR_1009,
  ],

  PREFERENCE_TEXT: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 24) || MESSAGE.INPUT_ERR_1005,
  ],

  PREFERENCE_PASSWORD_UPDATE_DAY: [
    // 必須
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    // 3桁の整数
    (value) => !value || /^([0-9]|[1-9][0-9]|[1-9][0-9][0-9])$/.test(value) || MESSAGE.INPUT_ERR_5003,
    // 範囲
    (value) => (!!value && parseInt(value, 10) >= 0 && parseInt(value, 10) <= 365) || MESSAGE.INPUT_ERR_1021,
  ],

  TENANT_CODE: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 8) || MESSAGE.INPUT_ERR_5014,
    (value) => (!!value && /[0-9]{8}/.test(value)) || MESSAGE.INPUT_ERR_5014,
  ],

  MOBILE_PHONE_NUMBER: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 11) || MESSAGE.INPUT_ERR_5007,
    (value) => (!!value && /^070|^080|^090/.test(value)) || MESSAGE.INPUT_ERR_5007, // 070/080/090で始まるかをチェック
    (value) => (!!value && /[0-9]{11}/.test(value)) || MESSAGE.INPUT_ERR_5007, // 半角数字11桁¥
  ],

  TEMPLATE_DISPLAY_NAME: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (value + '').length <= 80 || MESSAGE.INPUT_ERR_1019,
    (value) => !/[¥\\/:*?"<>|]/.test(value) || MESSAGE.INPUT_ERR_5015,
  ],

  CUSTOMER_NAME: [
    (value) => (!!value && (value + '').trim().length >= 1) || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').length <= 50) || MESSAGE.INPUT_ERR_1001,
  ],

  // パスワード
  PASSWORD(params) {
    const max = 36
    const min = 8
    const { required, } = params

    const typeCheck = (value) => {
      /*
        半角英大文字、半角英小文字、半角数字、一部半角記号のみかをチェック
        以下の特殊文字を許可
        https://docs.aws.amazon.com/ja_jp/cognito/latest/developerguide/user-pool-settings-policies.html

        ※ 正規表現の説明
          ・a-z
            abcdefghijklmnopqrstuvwxyz

          ・A-Z
            ABCDEFGHIJKLMNOPQRSTUVWXYZ

          ・0-9
            0123456789

          ・!-/:-@¥[-`{-~
            !"#$%&'()*+,-./:;<=>?@[\]^_`{|}~
      */
      return /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/.test(value)
    }

    const numberCheck = (value) => {
      // 数字含む
      return /[0-9]/.test(value)
    }

    const upperCaseCheck = (value) => {
      // 大文字英字含む
      return /[A-Z]/.test(value)
    }

    const lowerCaseCheck = (value) => {
      // 小文字英字含む
      return /[a-z]/.test(value)
    }

    const rules = [
      (value) => !value || (value + '').length >= min || MESSAGE.INPUT_ERR_5001,
      (value) => !value || (value + '').length <= max || MESSAGE.INPUT_ERR_5001,
      (value) => !value || typeCheck(value) || MESSAGE.INPUT_ERR_5001,
      (value) => !value || numberCheck(value) || MESSAGE.INPUT_ERR_5001,
      (value) => !value || lowerCaseCheck(value) || MESSAGE.INPUT_ERR_5001,
      (value) => !value || upperCaseCheck(value) || MESSAGE.INPUT_ERR_5001,
    ]

    if (required) {
      const rule = (value) => !!value || MESSAGE.INPUT_ERR_1000
      rules.unshift(rule)
    }

    return rules
  },

  ORIGINAL_USER_ID() {
    const max = 50

    const typeCheck = (value) => {
      let check = value + ''

      check = check.replace(/[0-9]/g, '')
      check = check.replace(/[a-zA-Z]/g, '')
      check = check.replace(/[-+@.!#_]/g, '')

      return check.length === 0
    }

    const rules = [
      (value) => !!value || MESSAGE.INPUT_ERR_1000,
      (value) => (!!value && typeCheck(value)) || MESSAGE.INPUT_ERR_5000,
      (value) => (!!value && (value + '').length <= max) || MESSAGE.INPUT_ERR_1001,
    ]

    return rules
  },

  // 契約ID
  ORIGINAL_CONTRACT_ID(params) {
    const rules = []
    const required = params?.required || false
    const max = params?.max || false

    // 必須
    if (required) {
      rules.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    // 形式
    rules.push((value) => (!!value && /^[a-zA-Z0-9-+@.!#_]*$/.test(value)) || MESSAGE.INPUT_ERR_5000)

    // 最大
    if (max) {
      rules.push((value) => (value + '').length <= max || maxMessage(max))
    }

    return rules
  },

  // 日付
  DATE_FORMAT(params) {
    const rules = []

    // 必須
    if (params?.required) {
      rules.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    // スラッシュ形式：YYYY/MM/DD
    if (params?.isSlash) {
      rules.push((value) => (!!value && /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/.test(value)) || MESSAGE.INPUT_ERR_5028)
    }

    return rules
  },

  // 交付日（保証開始日）
  WES_OPEN_DATE(params) {
    const rules = []

    // 必須
    if (params?.required) {
      rules.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    // スラッシュ形式：YYYY/MM/DD
    if (params?.isSlash) {
      rules.push((value) => (!!value && /^[0-9]{4}\/(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])$/.test(value)) || MESSAGE.INPUT_ERR_5028)
    }

    // 日付の整合性
    if (params?.deliveryPlanDate) {
      rules.push((value) => moment(params.deliveryPlanDate).isSameOrAfter(moment(value)) || MESSAGE.INPUT_ERR_9005)
    }

    return rules
  },

  // 名前（カナ）
  NAME_KANA(params) {
    const { max, required, } = params
    const checkList = []

    if (required) {
      checkList.push((value) => !!value || MESSAGE.INPUT_ERR_1000)
    }

    /*
     * 正規表現
     * 1. aからz
     * 2. AからZ
     * 3. 0から9
     * 4. ァからヴ と ー（長音記号） ※「ヵヶ」は含めない
     * 5. 半角スペース と 全角スペース
     * 6. &'(),-. （記号）
     */
    // eslint-disable-next-line no-irregular-whitespace
    checkList.push((value) => (!!value && /^[a-zA-Z0-9ァ-ヴー( |　)&'(),-.]*$/.test(value)) || MESSAGE.INPUT_ERR_5029)

    if (max) {
      checkList.push((value) => (value + '').length <= max || maxMessage(max))
    }

    return checkList
  },

  PARTNER_NAME: [
    // 空文字の場合、許可(true)。空文字ではない場合、入力されている値が、スペース（全角/半角）のみかチェック。
    (value) => !value ? true : (value + '').trim().length >= 1 || MESSAGE.INPUT_ERR_5031,
    (value) => (value + '').length <= 50 || MESSAGE.INPUT_ERR_1001,
  ],

  PARTNER_COMPANY_NAME: [
    (value) => !!value || MESSAGE.INPUT_ERR_1000,
    (value) => (!!value && (value + '').trim().length >= 1) || MESSAGE.INPUT_ERR_5031,
    (value) => (!!value && (value + '').length <= 48) || MESSAGE.INPUT_ERR_1012,
  ],

  // アクセスコード
  ACCESS_CODE: [
    (value) => (!!value && /^([0-9]{8})$/.test(value)) || MESSAGE.INPUT_ERR_5014,
  ],

}
