/*
 * FIPS物件情報ストア
 */
export default {
  state: {
    fipsArticles: [],
  },

  // stateにセットされた情報を取得する。
  // ページ側では ...mapGetters({ ~ }) で参照する。
  getters: {
    getFipsArticles: (state) => {
      return state.fipsArticles
    },
    getFipsArticle: (state) => (fipsArticleId) => {
      return state.fipsArticles.find((fipsArticle) => {
        return fipsArticle.id === fipsArticleId
      })
    },

  },

  // 実際にstateを更新する処理をここに記述する。
  // actionsから呼ぶプライベート関数的な扱いなので、直接呼ばないこと。
  mutations: {
    savefipsArticles(state, data) {
      state.fipsArticles = data
    },
  },

  // apiからデータを取得し、mutation経由でstateを更新する処理を記述する。
  // ページ側ではfetch時に呼び出す。
  actions: {
    async fetchFipsArticles({ commit }, article_id) {
      await this.$axios.get(`/articles/${article_id}/fips_articles`).then((response) => {
        let fipsArticles
        if (response.data?.body?.data) {
          fipsArticles = response.data.body.data
        } else {
          // 0件
          fipsArticles = []
        }
        commit('savefipsArticles', fipsArticles)
      })
    },

    // FIPS物件情報登録
    async addFipsArticles({ commit }, payload) {
      return await this.$axios.post(`/articles/${payload.articleId}/fips_articles`, {
        fips_article_number: payload.fipsArticleNumber,
        fips_article_name: payload.fipsArticleName
      })
    },

    // FIPS物件情報更新
    async updateFipsArticles({ commit }, payload) {
      return await this.$axios.put(`/articles/${payload.articleId}/fips_articles/${payload.fipsArticleId}`, {
        fips_article_number: payload.fipsArticleNumber,
        fips_article_name: payload.fipsArticleName
      })
    },

    // FIPS物件情報削除
    async deleteFipsArticles({ commit }, payload) {
      return await this.$axios.delete(`/articles/${payload.articleId}/fips_articles/${payload.fipsArticleId}`)
    },

  },
}
