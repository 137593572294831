
export const DRAWER_TYPE = {
  UPDATE: 'update',
  CREATE: 'create',
}

export default {
  state: {
    title: '',
    type: '',
    isShow: false,
    partner: {},
  },

  getters: {
    getDrawerState: (state) => state,
  },

  mutations: {
    saveDrawer(state, data) {
      state.type = data.type
      state.title = data.title
      state.isShow = data.isShow
      state.partner = data.partner
    },

  },

  actions: {
    showDrawer({ commit }, payload) {
      if (!payload) {
        commit('saveDrawer', {
          type: DRAWER_TYPE.CREATE,
          title: '関係者追加',
          isShow: true,
          partner: {},
        })
      } else {
        commit('saveDrawer', {
          type: DRAWER_TYPE.UPDATE,
          title: '関係者詳細（法人）',
          isShow: true,
          partner: Object.assign({}, payload)
        })
      }
    },

    closeDrawer({ commit }) {
      commit('saveDrawer', {
        title: '',
        type: '',
        isShow: false,
        partner: {},
      })
    },

  }
}
