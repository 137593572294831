
import CONST from '@/data/const'

const defaultFilterId = CONST.DISTRIBUTION_DOCUMENT.FILTER_STATUS.CONFIRMED

export default {
  state: {
    isShow: false,
    filterId: defaultFilterId,
  },

  getters: {
    isShow: (state) => state.isShow,
    getFilterId: (state) => state.filterId,

  },

  mutations: {
    saveShowHide(state, isShow) {
      state.isShow = isShow
    },

    saveFilterId(state, filterId) {
      state.filterId = filterId
    },

  },

  actions: {
    show({ commit }, params) {
      const { filterId } = params
      commit('saveShowHide', true)
      commit('saveFilterId', filterId)
    },

    hide({ commit }) {
      commit('saveShowHide', false)
      commit('saveFilterId', defaultFilterId)
    },

    setFilterId({ commit }, filterId) {
      commit('saveFilterId', filterId)
    }
  },
}
