export default {
  state: {
    isShow: false,
    customerId: '',
    customer: {},
  },

  getters: {
    getCustomerId: (state) => {
      return state.customerId
    },
    isShow: (state) => {
      return state.isShow
    },
    getDrawerState: (state) => state,

    getCustomer: (state) => {
      return state.customer
    },

  },

  mutations: {
    setShowHide(state, payload) {
      state.isShow = payload
    },
    setCustomerId(state, payload) {
      state.customerId = payload
    },

    saveDrawer(state, data) {
      state.isShow = data
    },
    saveCustomer(state, data) {
      state.customer = data
    },
  },

  actions: {
    setShowHide({ commit }, payload) {
      commit('saveDrawer', payload)
    },
    setCustomer({ commit }, payload) {
      commit('saveCustomer', { ...payload, })
    },
  },
}
