import Vue from 'vue'
// eslint-disable-next-line
import Amplify from 'aws-amplify'

const awsconfig = {
  Auth: {
    region: process.env.REGION,
    userPoolId: process.env.USER_POOL_ID,
    userPoolWebClientId: process.env.USER_POOL_WEB_CLIENT_ID,
  },
  Storage: {
    AWSS3: {
      bucket: '',
      region: process.env.REGION,
    },
  },
}

Amplify.configure(awsconfig)
Vue.use(Amplify)
