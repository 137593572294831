
export default {

  state: {
    isShow: false,
    list: null,
    phase: null,
  },

  getters: {
    isShow: (state) => {
      return state.isShow
    },

    getList: (state) => {
      return state.list
    },

    getPhase: (state) => {
      return state.phase
    }
  },

  mutations: {
    saveShowHide(state, payload) {
      state.isShow = payload
    },

    saveList(state, payload) {
      state.list = payload
    },

    savePhase(state, payload) {
      state.phase = payload
    },
  },

  actions: {
    show({ commit }, params) {
      const { list, phase } = params
      commit('saveShowHide', true)
      commit('saveList', list)
      commit('savePhase', phase)
    },

    closeDrawer({ commit }) {
      commit('saveShowHide', false)
    },

    setShowHide({ commit }, params) {
      commit('saveShowHide', params)
    },

    setList({ commit }, params) {
      commit('saveList', params)
    }
  },
}
