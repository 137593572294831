
export default {
  state: {
    customers: [],
  },

  getters: {
    getArticleCustomers: (state) => state.customers,
  },

  mutations: {
    saveCustomers(state, data) {
      state.customers = data
    },
  },

  actions: {
    async fetchArticleCustomers({ commit }, params) {
      const { articleId, type, distributionDocumentId } = params

      // クエリー文字列の配列を作成
      const queryList = []
      if (type) {
        queryList.push(`type=${type}`)
      }
      if (distributionDocumentId) {
        queryList.push(`distribution_document_id=${distributionDocumentId}`)
      }
      // クエリー文字列を整形
      let query = ''
      if (queryList.length > 0) {
        query = '?' + queryList.join('&')
      }

      const res = await this.$axios.get(`/article_customers/${articleId}${query}`)
      const customers = res?.data?.body?.data ? res.data.body.data : []

      commit('saveCustomers', customers)
    },
  },
}
