export default async ({ params, redirect, store }) => {
  // ログインチェック
  // storeにaccount情報があるかどうか
  if (!store.getters['customer/customerAccount/isLogined']) {
    // APIからアカウント情報取得
    await store.dispatch('customer/customerAccount/fetchAccount')
  }

  // account情報がない場合は、ログイン画面に遷移する
  // URLのcustomer_keyとセッション内のcustomer_keyに違いがある場合は、ログイン画面に遷移する
  if (!store.getters['customer/customerAccount/isLogined'] ||
    store.getters['customer/customerAccount/getCustomerKey'] !== params.customer_key) {
    // ログインURL
    let loginUrl = `/customer/${params.customer_key}/login`
    // URLにアクセストークンがある場合は、アクセストークンありのlogin画面に遷移する
    if ('accessToken' in params) {
      loginUrl += `?at=${params.accessToken}`
    }

    return redirect(loginUrl)
  }
}
