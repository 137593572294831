import utils from '@/data/utils'
import CONST from '@/data/const'

export const VALIDATION_MODE = {
  ONLY_COUNT: 'only_count',
  CSV_CHECK: 'csv_check',
}

// バリデーションエラーがない場合に戻す値
const NO_ERROR = ''

// 引渡予定日
const _checkDeliveryPlanDate = (value, row, required = true) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.DATE_FORMAT({
      required,
      isSlash: !!value, // 値があるときのみ isSlash を true に
    }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:delivery_plan_date,入力値:${value},行番号:${row})` : NO_ERROR
}

// 契約情報ID
const _checkContractInfoId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.INTEGER({ required: true, max: 20 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:contract_info_id,入力値:${value},行番号:${row})` : NO_ERROR
}

// WES物件情報ID
const _checkWesArticleInfoId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: false }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:wes_article_info_id,入力値:${value},行番号:${row})` : NO_ERROR
}

// WES物件情報ID
const _checkWesArticleCode = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: true, max: 6 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:wes_article_code,入力値:${value},行番号:${row})` : NO_ERROR
}

// 保証額（手付額）
const _checkWarrantyAmount = (value, row, required = true) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.INTEGER({ required, max: 10 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:warranty_amount,入力値:${value},行番号:${row})` : NO_ERROR
}

// 部屋・区画番号
const _checkLotNumber = (value, row, required = true) => {
  const res = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required, max: 20 }),
    value,
  })
  return res ? `${res}(キー名:lot_number,入力値:${value},行番号:${row})` : NO_ERROR
}

// 顧客名１
const _checkCustomerName = (value, row, required = true) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required, max: 50 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:customer_name,入力値:${value},行番号:${row})` : NO_ERROR
}

// 顧客名２
const _checkCustomerName2 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 50 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:customer_name_2,入力値:${value},行番号:${row})` : NO_ERROR
}

// 顧客名３
const _checkCustomerName3 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 50 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:customer_name_3,入力値:${value},行番号:${row})` : NO_ERROR
}

// 顧客名４
const _checkCustomerName4 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 50 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:customer_name_4,入力値:${value},行番号:${row})` : NO_ERROR
}

// 住所１
const _checkAddress1 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 30 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:address_1,入力値:${value},行番号:${row})` : NO_ERROR
}

// 住所２
const _checkAddress2 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 35 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:address_2,入力値:${value},行番号:${row})` : NO_ERROR
}

// 住所３
const _checkAddress3 = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.BASE({ required: false, max: 35 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:address_3,入力値:${value},行番号:${row})` : NO_ERROR
}

// 証書交付日
const _checkOpenDate = (value, deliveryPlanDate, row, required = true) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.WES_OPEN_DATE({
      required,
      isSlash: !!value, // 値があるときのみ isSlash を true に
      deliveryPlanDate,
    }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:open_date,入力値:${value},行番号:${row})` : NO_ERROR
}

// 契約ID
const _checkOriginalContractId = (value, row) => {
  const errorMessage = utils.validation.checkFromRules({
    ruleList: CONST.RULES.ORIGINAL_CONTRACT_ID({ required: true, max: 64 }),
    value,
  })
  return errorMessage ? `${errorMessage}(キー名:original_contract_id,入力値:${value},行番号:${row})` : NO_ERROR
}

// WES物件コード（wes_article_code）がWES物件情報一覧に存在しないするか
const _checkContainWesArticleCode = (value, row, list) => {
  const wes_article_code = parseInt(value, 10)
  // 含まない場合 false
  const res = list.includes(wes_article_code)

  return !res ? `${CONST.MESSAGE.INPUT_ERR_9004}(キー名:wes_article_code,入力値:${value},行番号:${row})` : NO_ERROR
}

/*
 * バリデーションチェック
 * @params {object} item チェックする値
 * @params {int} params.row csv行番号
 * @params {string} params.mode モード（VALIDATION_MODE で指定）
 * @return {array} エラーリスト（エラーなしの場合は、[]）
 */
const validationCheck = (item, params = {}) => {
  const row = params?.row || ''
  const mode = params?.mode || VALIDATION_MODE.ONLY_COUNT
  let checkList = () => { }

  // チェック内容を決定
  switch (mode) {
    case VALIDATION_MODE.CSV_CHECK:
      // CSV 登録用：CSV順に実行
      checkList = (item, row) => [
        _checkContractInfoId(item.contract_info_id, row), // 契約情報ID（ID）
        _checkOriginalContractId(item.original_contract_id, row), // 契約ID
        _checkWesArticleCode(item.wes_article_code, row), // WES物件コード
        _checkLotNumber(item.lot_number, row, false), // 区画番号 必須チェック無し
        _checkCustomerName(item.customer_name, row, false), // 名前1（契約者） 必須チェック無し
        _checkCustomerName2(item.customer_name_2, row), // 名前2（共有者）
        _checkCustomerName3(item.customer_name_3, row), // 名前3（共有者）
        _checkCustomerName4(item.customer_name_4, row), // 名前4（共有者）
        _checkAddress1(item.address_1, row), // 住所１
        _checkAddress2(item.address_2, row), // 住所２
        _checkAddress3(item.address_3, row), // 住所３
        _checkWarrantyAmount(item.warranty_amount, row, false), // 保証額 必須チェック無し
        _checkOpenDate(item.open_date, item.delivery_plan_date, row, false), // 交付日（保証開始日） 必須チェック無し
        _checkDeliveryPlanDate(item.delivery_plan_date, row, false), // 引渡予定日 必須チェック無し

        // WES物件コード（wes_article_code）がWES物件情報一覧に存在しないするか
        _checkContainWesArticleCode(item.wes_article_code, row, params.wesArticleCodeList),
      ]
      break

    // ボタンの活性・非活性 用
    case VALIDATION_MODE.ONLY_COUNT:
    default:
      checkList = (item, row) => [
        _checkDeliveryPlanDate(utils.date.format(item.delivery_plan_date), row), // 引渡予定日: momentを実施して確認
        _checkContractInfoId(item.wes_warranty_issue_info.contract_info_id, row), // 契約情報ID
        _checkWesArticleInfoId(item.wes_warranty_issue_info.wes_article_info_id, row), // WES物件情報ID
        _checkWarrantyAmount(item.wes_warranty_issue_info.warranty_amount, row), // 保証額（手付額）
        _checkLotNumber(item.wes_warranty_issue_info.lot_number, row), // 部屋・区画番号
        _checkCustomerName(item.wes_warranty_issue_info.customer_name, row), // 顧客名
        _checkCustomerName2(item.wes_warranty_issue_info.customer_name_2, row), // 顧客名２
        _checkCustomerName3(item.wes_warranty_issue_info.customer_name_3, row), // 顧客名２
        _checkCustomerName4(item.wes_warranty_issue_info.customer_name_4, row), // 顧客名２
        _checkAddress1(item.wes_warranty_issue_info.address_1, row), // 住所１
        _checkAddress2(item.wes_warranty_issue_info.address_2, row), // 住所２
        _checkAddress3(item.wes_warranty_issue_info.address_3, row), // 住所３
        _checkOpenDate(utils.date.format(item.wes_warranty_issue_info.open_date), utils.date.format(item.delivery_plan_date), row), // 交付日（保証開始日）: momentを実施して確認
      ]
      break
  }

  // チェック実行
  const error_list = checkList(item, row)

  // 配列の形で戻す
  return error_list.filter((item) => item !== NO_ERROR)
}

export default {
  validationCheck,
}
