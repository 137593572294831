
import { mapGetters, mapActions /* mapMutations */ } from 'vuex'
import CONST from '@/data/const'

export default {
  name: 'CommonAppBar',
  components: {
    LoginUserDrawer: () => import('@/components/organisms/LoginUserDrawer'),
    AuthorizationChip: () => import('@/components/atoms/AuthorizationChip'),
    ButtonIcon: () => import('@/components/atoms/ButtonIcon'),
  },
  props: {
    hideSidbarBtn: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      CONST,
      notificationDrawer: false,
      searchDrawer: false,
    }
  },

  computed: {
    ...mapGetters({
      getThemeMode: 'themeConfig/getThemeMode',
      account: 'account/getAccount',
    }),
    isSystemAdmin() {
      return this.$common.isSystemAdmin()
    },
    accessableSettingPage() {
      // システム管理者か、管理物件がある場合、アクセス可能
      return this.$common.isSystemAdmin() || this.$common.hasManagedArticle()
    },
  },
  created() {
    // this.setAuthData().then( () => {console.log('VerticallAppBar');} );
  },
  methods: {
    ...mapActions({
      changeVerticalSidebarDrawer: 'themeConfig/changeVerticalSidebarDrawer',
      changeVerticalSidebarMini: 'themeConfig/changeVerticalSidebarMini',
      setAuthData: 'authData/setAuthData',
      showloginUserDrawer: 'loginUserDrawer/show',
    }),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer()

      // this.$emit("update:mini-variant");
      // console.log("check");
    },
    onClickUser() {
      this.showloginUserDrawer()
    },
  },
}
